import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

export default class GoodSamaritians extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="Good Samaritians" pageName="Project Arm" />

        <section className="about_area section_gap">
          <div className="container">


         
           
            <div className="row">
              <div className="col-md-12 d_flex">
                <div className="about_content flex">
                  <h5 className="title_color"> Good Samaritians</h5>
                  <p style={{ textAlign: "justify" }}>

                  This provides opportunities for raising material, financial and moral support for missions. 


                

<br/><br/>Student Mission Support Scheme (SMSS): Regularly mobilize Christian students in groups of 100 /missionary, to support feeding of specific partner missionaries with their campus fellowships/ groups as bases of their operations, mobilize SMC members to initiate SMSS groups on campuses, regularly train SMC members (student members of FSM) on campuses in sustainable management of SMSS for support feeding of Christian missionaries and regularly raise awareness on the need for supporting feeding of Christian missionaries through SMSS. Regularly seek Christian missionaries to be supported by SMSS, monitor the impact of SMSS on partner missionaries, and link individuals and organizations that can support the realization of the task of the Unit.
<br/><br/>Missionary Empowerment Scheme (MES): Regularly mobilize support from members and associates to support specific missionaries in vocations they can use to generate fund in order to reduce their dependence on external funding, regularly raise awareness on the need to empower Christian missionaries for self-sustaining, regularly enlist missionaries to be supported, mobilize support and seek partnership from individuals, networks or like-minded organizations in addressing the task of the Unit. 
<br/><br/>11 to 1 Mission Support Scheme (for staff of FSM): Regularly mobilize financial support for sustaining staff members of FSM to reduce their dependence on external funding, regularly raise awareness on the need to empower Christian missionaries for self-sustaining, regularly enlist missionaries to be supported, mobilize support and seek partnership from individuals, networks or like-minded organizations in addressing the task of the Unit. Regularly raise awareness on the need for 11 to 1 Mission Support Scheme; regularly mobilize financial support for sustaining staff members of FSM, link individuals and organizations that can support the realization of the task of the Unit.
<br/><br/> Missionary Children Educational Support: Regularly mobilize members and associates of FSM to support the education of missionary children, regularly raise awareness on the need to support the education of missionary children, and regularly seek missionary children that need educational support. Seek organizations, churches or individuals that can take up educational support of missionary children.
<br/><br/> Christian Missionary Medical Support Scheme (CMMSS): Provide medical counsel/ treatment for FSM members freely or at reduced cost, Regularly raise awareness on the need to support medical issues of missionaries, raising support (cash and kind) for treating Christian missionaries, solicit partnership with hospitals, networks or like-minded organizations for treating Christian missionaries.
<br/><br/> Missions Guest Rooms/ House (MGR/H): Oversee the utilization of the Guest rooms/house facilities of FSM in accommodating FSM missionary-staff members and other members in transit or retreat, regularly raise awareness on the need to support accommodation of missionaries, regularly raise support for addressing rents, maintenance, utilities and other essential needs of the mission Guest room/houses, accommodate partner missionaries on transit or retreat, Link individuals and organizations that can support definite things needed to realize the task of the Unit.
<br/><br/> Special Projects of FSM (SPF): Regularly raise awareness of special projects of FSM, `Mobilize members and associates to support special projects of FSM, regularly mobilize support among friends and associates of FSM, regularly solicit support/ partnership with individuals, organizations, etc. that can assist in fulfilling the task of the Unit.

<br/>





                  
                  </p>
                </div>
              </div>
              
                

              {/* <div className="col-md-12 features_item">
              <p>
              <br/>Information and Communication Technology (ICT): Regularly develop online tools for missions, train members of the team on specific areas of ICT for effective contributions to missions, and raise awareness of the need for ICT in missions. Also, coordinate reaching out via website and social media and provide ICT supports for partner missionaries of FSM. Link up with individuals and organizations that can support realization of the task of the Unit. Development/management of websites and Apps for FSM activities.
              <br /> <br/>Publication/editorial (PE): Regularly raise awareness on the need for publications in missions and support missionaries without capacity on newsletter, tracts writing, and other publication processes. Link up with individuals and organizations that can support realization of the task of the Unit. Train members on writing skills (tracts, books, etc.), services within FSM; newsletters, magazines, websites, publication, etc. 
              <br /><br/>	Research (R): Regularly research into new areas of FSM’s operations/ improvement implementing activities and publish in appropriate platforms of FSM, including the website, awareness of the need for research in missions. Regularly train members on research tools in Christian missions. Alike, regularly collaborate with other mission agencies in discovering unreached people groups, needs of missions or other related issues. Link up with individuals and organizations that can support realization of the task of the Unit.
              <br/><br/>Audio-visuals (AV): Regularly train members on audio-visual recording skills, raise awareness on the need for audio-visuals in missions, support missionary partners of FSM on audio-visuals and link individuals and organizations that can support realization of the task of the Unit. Update of media appropriate pages of our website and social media platforms with photographs and video clips of FSM activities. Recording and documentation of FSM activities.
              <br/><br/> Library Services (LS): Regularly raise awareness of the need for learning resources in missions. Also, lending of books to missionaries or assistance in developing their libraries, regularly share information about new books uploaded on the Library resources’ page. Link individuals and organizations that can support realization of the task of the Unit. Development of library (physical and virtual) for FSM, train members of the team on the collection of Christian literature and encouraging act of reading among FSM members, regularly update Library Resources page of FSM on our website with useful books, videos, presentations, weblinks, etc. addressing core values of FSM.

               
              </p>
          





              </div> */}
            </div>
          </div>
        </section>

       
       
        <Footer />
      </div>
    );
  }
}
