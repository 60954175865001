import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

export default function LookUpdateEmbedded(props) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [data, setResponse] = useState({});
  const [returnMessage, setReturnMessage] = useState({});
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { handle } = props.match.params;
    axios
      .get(`/v1/api/look/${handle}`)
      .then((res) => {
        setFields(res.data);
        setLoading(false);
        console.log("Look details value", res.data);
      });
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setFields({ ...fields, [name]: event.target.value });
  };

  const handleSubmit = (event) => {
    setSubmitLoading(true);
    setReturnMessage({});
    event.preventDefault();
    axios
      .put(`/v1/api/look/${fields.id}`, fields, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setResponse(response.data);
          setSubmit(true);
        }
        setSubmitLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          const response_error = error.response.data;
          setReturnMessage(response_error);
          console.log(response_error);
          setSubmitLoading(false);
        }
      });
  };

  return (
    <div>
      <div className="col-md-6  align-self-center">
        <div className="donation_form registration">
          {submit ? (
            <div>
              <h3>LOOK Update Form Submitted Successfully</h3>
              <p>
                Hi <strong>{data.name}</strong> , Your registration update is
                successful.
                <br />
              </p>

              <Link to="/blog" className="btn_hover btn_hover_two">
                Visit Blog
              </Link>
              <br />
              <br />

              <Link to="/" className="genric-btn link">
                Back to Home Page
              </Link>
            </div>
          ) : (
            <form onSubmit={handleSubmit} method="post">
              <h3>LOOK 2022 Update Registration</h3>
              <p>
                <Link to="/look"> LOOK </Link>is an annual mission conference of
                the Foundation for Student Mission. (FSM), with a commission to
                mobilize and equip Christian students as workforce for several
                Churches and non-Church mission activities. <br />
                <br />
                <strong>THEME: UNSTOPPABLE - MATTHEW 23:34 </strong>
                Wherefore, behold, I send unto you prophets, and wise men, and
                scribes: and some of them ye shall kill and crucify; and some of
                them shall ye scourge in your synagogues, and persecute them
                from city to city:
              </p>
              <hr />

              {loading ? (
                <Loader
                  type="TailSpin"
                  color="#FF0000"
                  height={50}
                  width={50}
                />
              ) : (
                <div className="row">
                  <div className="form-group col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                      value={fields.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group col-md-12 ">
                    <select
                      className="form-control"
                      name="attendance_mode"
                      onChange={handleChange}
                      required
                    >
                      <option value=""> Attendance Mode </option>
                      <option value="Physical">Physical</option>
                      <option value="Virtual"> Virtual</option>
                    </select>
                  </div>

                  <div className="col-md-12 text-center">
                    {submitLoading ? (
                      <Loader
                        type="TailSpin"
                        color="#FF0000"
                        height={50}
                        width={50}
                      />
                    ) : (
                      <button
                        type="submit"
                        className="btn_hover success"
                        disabled={submitLoading}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
