import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

export default class ChristianMission  extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="Christian Mission" pageName="Project Arm" />

        <section className="about_area section_gap">
          <div className="container">


         
           
            <div className="row">
              <div className="col-md-12 d_flex">
                <div className="about_content flex">
                  <h5 className="title_color">CHRISTIAN MISSION EDUCATIONAL SERVICES</h5>
                  <p style={{ textAlign: "justify" }}>


                

                  This provides opportunity for missions/evangelism minded Christians (especially those working in the academia, research institutes, Youth Corp members, postgraduate students and final year students of institutions of higher learning) in Africa and beyond.  The following are the training units of this arm. 
    

<br/><br/>On-campus Mission School (OMS)/ Youths in Missions (YiM): This coordinates preparatory classes/tutorials for contact classes of OMS/YiM at specific locations, also coordinates OMS/YiM on campuses/churches for raising workforce in missions. 
              <br /> <br/>Training of Trainers (ToT): This coordinates the ToT for members of FSM and ToT for those that will be able to pioneer SMC in various institutions of higher learning. 
              <br /><br/>	Training of Master Trainers: This organizes short-term courses on missions. Training of those that can train others in every aspect of ToT for OMS/YiM, regularly conduct physical or online short-term courses on effective evangelism, spiritual warfare, etc.






                  
                  </p>
                </div>
              </div>
              
                

              {/* <div className="col-md-12 features_item">
              <p>
                 

               
              </p>
   
              </div> */}
            </div>
          </div>
        </section>

       
       
        <Footer />
      </div>
    );
  }
}
