import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import Loader from "react-loader-spinner";

const Player = (props) => {
  const [loading, setLoading] = useState(true);
  const onReady = (params) => {
    setLoading(false);
  };

  const hideComponent = {
    display: "flex",
    flexDirection: "row",
  };

  // return (
  //   <div style={props.audio ? hideComponent : { width: "100%" }}>
  //     {loading && props.audio ? (
  //       <Loader
  //         type="TailSpin"
  //         color="#000"
  //         height={30}
  //         width={30}
  //         style={{ padding: "10px" }}
  //       />
  //     ) : null}

  //     <ReactPlayer {...props} onReady={onReady} />
  //   </div>
  // );

  return <ReactPlayer {...props} onReady={onReady} />;
};

export default Player;
