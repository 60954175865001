import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class EventComponent extends Component {
  render() {
    return (
      <div className="col-lg-4 col-sm-6">
        <div className="event_post">
          <img
            src={
              this.props.imageLink ===
              "https://fsmissionadmin.s3-us-west-2.amazonaws.com/uploads/default.jpg"
                ? "image/default.jpg"
                : this.props.imageThumb
            }
            alt=""
          />

          {/* <Link
            to={{
              pathname: "/eventdetails",
              state: {
                event: this.props.event,
              },
            }}
          >
            <h2 className="event_title">{this.props.title} </h2>
          </Link> */}

          <Link to={`/events/${this.props.eventID}`} >
          <h2 className="event_title">{this.props.title} </h2>
            </Link>



          <ul className="list_style sermons_category event_category">
            <li>
              <i className="lnr lnr-calendar-full"></i>
              {this.props.startDate} {this.props.endDate}
            </li>
            {/* <li>
              <i className="lnr lnr-apartment"></i>
              {this.props.eventVenue}
            </li> */}

            <li>
              <i className="lnr lnr-apartment"></i>
              {this.props.location}
            </li>
            {/* <li>
              <i className="lnr lnr-location"></i> {this.props.location}
            </li> */}
          </ul>

          {/* <Link
            to={{
              pathname: "/eventdetails",
              state: {
                event: this.props.event,
              },
            }}
            className="btn_hover"
          >
            View Details
          </Link> */}

          <Link to={`/events/${this.props.eventID}`}   className="btn_hover">
          View Details
            </Link>


        </div>
      </div>
    );
  }
}

EventComponent.propType = {
  imageLink: PropTypes.string.isRequired,
  imageThumb: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  eventVenue: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};
