import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {parseISOString } from "../functions/Functions"; 

const VideoStreamComponentLive = (props) => {

    return (    
      <div className="col-lg-6 col-sm-12">
        <iframe src={"https://www.facebook.com/plugins/video.php?height=314&href=https://www.facebook.com/"+ props.permalinkUrl +"/&show_text=false&width=560&t=0"} width={1150} height={648} style={{
            border: "none",
            overflow: "hidden",
            height: "220",
            overflow: "visible",
          }} scrolling="no" frameBorder="0"  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> 

      <div className="event_post">
          <ul className="list_style sermons_category event_category">
          <li>
              <i className="lnr lnr-tag"></i>
              {props.title}
            </li> 
            <li>
              <i className="lnr lnr-calendar-full"></i>
              { parseISOString(props.creation_time).toString()}
             
            </li>
          </ul>
        </div>
    </div> );
}

export default VideoStreamComponentLive;