import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 30) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["header_area"];
  if (scrolled) {
    navbarClasses.push("navbar_fixed");
  }

  const location = useLocation();

  return (
    <header className={navbarClasses.join(" ")}>
      <div className="header_top">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-5">
              <ul className="nav social_icon">
                <li>
                  <a
                    href="https://facebook.com/FSMission"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/OfficialFSM_SMC"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/fsmissions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa fa-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-7">
              <div className="top_btn d-flex justify-content-end">
                <a
                  href="/admin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dashboard
                </a>

                <a href="#donationform">Donate Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand logo_h" to="/">
            <img src="/image/Logo.png" alt="" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <div
            className="collapse navbar-collapse offset"
            id="navbarSupportedContent"
          >
            <ul className="nav navbar-nav menu_nav ml-auto">
              <li
                className={
                  location.pathname === "/" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>

              <li
                className={
                  location.pathname === "/events"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/events" className="nav-link">
                  Events
                </Link>
              </li>

                <li
                className={
                  location.pathname === "/gallery"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/gallery" className="nav-link">
                  Gallery
                </Link>
              </li>



              <li
                className={
                  location.pathname === "/livestream"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/livestream" className="nav-link">
                  Live Stream
                </Link>
              </li>

              <li
                className={
                  location.pathname === "/album"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/album" className="nav-link">
                  Music
                </Link>
              </li>

              {/* <li className="nav-item submenu dropdown">
                <Link
                  to="#"
                  className="nav-link"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Media Center
                </Link>

                <ul className="dropdown-menu">
                


                  <li className="nav-item">
                    <Link to="#" className="nav-link">
                      Library Resources
                    </Link>
                  </li>
                 
                  <li className="nav-item">
                    <Link to="#" className="nav-link">
                      Gallery
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item submenu dropdown">
                <Link
                  to="#"
                  className="nav-link"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Project Arm
                </Link>

                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <Link to="/christianmission" className="nav-link">
                      Christian Mission
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/theexplorer" className="nav-link">
                      The Explorer
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/missioncommunication" className="nav-link">
                      Communication
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/theovercomers" className="nav-link">
                      The Overcomers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/blessedgeneration" className="nav-link">
                      Blessed Generation
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/goodsamaritans" className="nav-link">
                      Good Samaritians
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/jehoshaphatarmy" className="nav-link">
                      Jehoshaphat Amry
                    </Link>
                  </li>
                </ul>
              </li>

              {/* <li className="nav-item">
                <Link to="/bloglist" className="nav-link">
                  Blog
                </Link>
              </li> */}

              {/* <li
                className={
                  location.pathname === "/contact"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li> */}

              {/* <li
                className={
                  location.pathname === "/staff"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/staff" className="nav-link">
                  Staff
                </Link>
              </li> */}

              <li
                className={
                  location.pathname === "/blog" ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/blog" className="nav-link">
                  Blog
                </Link>
              </li>

              {/* <li
                className={
                  location.pathname === "/about"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li> */}




              <li className="nav-item submenu dropdown">
                <Link
                  to="#"
                  className="nav-link"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About US
                </Link>

                <ul className="dropdown-menu">
                  <li className="nav-item">
                  <Link to="/about" className="nav-link">
                  About
                </Link>
                  </li>
                  <li className="nav-item">
                  <Link to="/staff" className="nav-link">
                  Staff
                </Link>
                  </li>
                
                </ul>
              </li>

            </ul>
            {/* <ul className="nav navbar-nav navbar-right">
                <li className="nav-item">
                  <a href="#" className="search">
                    <i className="lnr lnr-magnifier"></i>
                  </a>
                </li>
              </ul> */}
          </div>
        </div>
      </nav>
    </header>
  );
}
