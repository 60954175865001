import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import "./App.css";
// import "./assets/css/bootstrap.css";
// import "./assets/vendors/linericon/style.css";
// import "./assets/css/font-awesome.min.css";
// import "./assets/vendors/owl-carousel/owl.carousel.min.css";
// import "./assets/vendors/lightbox/simpleLightbox.css";
// import "./assets/vendors/nice-select/css/nice-select.css";
// import "./assets/css/style.css";
// import "./assets/css/responsive.css";

import Routes from "./Routes";
import RoutesC3ma from "./RoutesC3ma";

class App extends Component {
  render() {
    const subdomain = window.location.host.split(".")[0];
    return <Router>{subdomain == "c3ma" ? <RoutesC3ma /> : <Routes />}</Router>;
  }
}

export default App;
