import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { PaystackButton } from "react-paystack";
import { useLocation } from "react-router-dom";

export default function ContestantRegistration() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [landingLoading, setLandingLoading] = useState(true);
  const [fields, setFields] = useState({});
  const [data, setResponse] = useState({});
  const [returnMessage, setReturnMessage] = useState({});
  const [submit, setSubmit] = useState(false);
  const [proceed, setProceed] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setFields(location.fields);
    axios
      .post(
        "https://fsmission.herokuapp.com/v1/api/check_c3ma_application",
        location.fields,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("here", response);
        if (response.data.status === "success") setProceed(false);
        setLandingLoading(false);
      });
  }, [location]);

  const handleSubmit = (reference) => {
    setSubmitLoading(true);
    setReturnMessage({});

    axios
      .post(
        "https://fsmission.herokuapp.com/v1/api/c3ma_application",
        {
          ...fields,
          paid: true,
          payment_reference: reference.reference,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          setResponse(response.data);
          setSubmit(true);
        }
        setSubmitLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          const response_error = error.response.data;
          setReturnMessage(response_error);
          // console.log(response_error);
          setSubmitLoading(false);
        }
      });
  };

  const handleSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    handleSubmit(reference);
  };

  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log('closed')
  };

  const componentProps = {
    text: "Purchase",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
    publicKey: "pk_live_d278bdeaf49155161704db962483042078f8879e",
    // publicKey: "pk_test_04e70a58b384511685e4215777eec7b5fa7ebfa8",
    reference: new Date().getTime().toString(),
    amount: 200000,
    email: fields.email,
    metadata: { page: "registration", name: fields.name },
    text: "Make Payment Now (NGN)",
  };

  const ghanacomponentProps = {
    text: "Purchase",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
    publicKey: "pk_live_d278bdeaf49155161704db962483042078f8879e",
    reference: new Date().getTime().toString(),
    amount: 200000,
    email: fields.email,
    currency: "GHS",
    text: "Make Payment Now (GHS)",
    metadata: { page: "registration", name: fields.name },
  };

  return (
    <div>
      {" "}
      <section className="breadcrumb_area br_image_c3ma">
        <div className="container">
          <div className="page-cover text-center">
            {/* <h2 className="page-cover-tittle">C3MA Award</h2> */}
            <ol className="breadcrumb">
              <li>
                <Link to="/"> Home </Link>
              </li>

              <li>
                <Link to="# ">C3MA </Link>
              </li>

              <li className="active"> make payment</li>
            </ol>

            <div className="row justify-content-center">
              <div className="col-md-6  align-self-center">
                <div className="donate_content ">
                  <h2>
                    C3MA 4.O Audition <br />
                  </h2>
                  {landingLoading ? (
                    <div>
                      <div class="col-md-12">
                        <div class="donation_form">
                          <Loader
                            type="TailSpin"
                            color="#FF0000"
                            height={50}
                            width={50}
                          />
                        </div>

                        <br />
                      </div>
                    </div>
                  ) : null}
                  {!proceed && !landingLoading && (
                    <div>
                      <div class="col-md-12">
                        <div class="donation_form">
                          <h3>Unable to proceed </h3>
                          Hi <strong>{fields.name}</strong> , Your record
                          already exist in our database
                          <br />
                        </div>

                        <br />
                      </div>
                    </div>
                  )}
                  ;
                  {proceed && submit && (
                    <div>
                      <div class="col-md-12">
                        <div class="donation_form">
                          <h3>Payment Successful </h3>
                          Hi <strong>{data.name}</strong> , Your registration is
                          successful. We willl send you a confirmation email.
                          <br />
                          <br />
                          <a
                            href="https://bit.ly/3xasxbJ"
                            className="btn_hover btn_hover_two"
                          >
                            Join {data.application_category} whatsapp group
                          </a>
                        </div>

                        <br />
                      </div>
                    </div>
                  )}
                  {proceed && !submit && (
                    <div>
                      <div>
                        <div class="col-md-12">
                          <div class="donation_form">
                            {submitLoading ? (
                              <Loader
                                type="TailSpin"
                                color="#FF0000"
                                height={50}
                                width={50}
                              />
                            ) : null}
                            <h3>Make Payment To Complete Registration </h3>
                            Hi {fields.name}, You are required to pay the sum of
                            NGN2000 to complete your application
                            <br />
                            <br />
                            <PaystackButton
                              {...componentProps}
                              className="btn_hover btn_hover_two"
                            />
                            {/* <PaystackButton
                            {...ghanacomponentProps}
                            className="btn_hover btn_hover_two"
                          /> */}
                          </div>
                          <br />
                        </div>
                      </div>

                      <br />
                    </div>
                  )}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
