import React from "react";
import { Switch, Route } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import BlogList from "./pages/BlogList";
import Contact from "./pages/Contact";
import Events from "./pages/Events";
import EventDetails from "./pages/EventDetails";
import Look from "./pages/Look";
import Oms from "./pages/Oms";
import SupportScheme from "./pages/SupportScheme";
import MissionTrips from "./pages/MissionTrips";
import Gim from "./pages/Gim";
import Album from "./pages/Album";
import AlbumDetails from "./pages/AlbumDetails";
import ChristianMission from "./pages/ChristianMission";
import TheExplorer from "./pages/TheExplorer";
import MissionCommunication from "./pages/MissionCommunication";
import TheOvercomers from "./pages/TheOvercomers";
import BlessedGeneration from "./pages/BlessedGeneration";
import GoodSamaritians from "./pages/GoodSamarians";
import JehoshaphatAmry from "./pages/JehoshaphantArmy";
import Staff from "./pages/Staff";
import StaffDetails from "./pages/StaffDetails";
import PreviewBlog from "./pages/PreviewBlog";
import Member from "./pages/Member";
import Elements from "./pages/Elements";
import LookRegistration from "./pages/LookRegistration";
import LookUpdate from "./pages/LookUpdate";
import LookUpdateEmbedded from "./pages/LookUpdateEmbedded";
import LiveStream from "./pages/LiveStream";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Budget from "./pages/Budget";
import Gallery from "./pages/Gallery";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/album" component={Album} />
      <Route exact path="/album/:handle" component={AlbumDetails} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/events/:handle" component={EventDetails} />
      <Route exact path="/oms" component={Oms} />
      <Route exact path="/support" component={SupportScheme} />
      <Route exact path="/trips" component={MissionTrips} />
      <Route exact path="/look" component={Look} />
      <Route exact path="/gim" component={Gim} />
      <Route exact path="/christianmission" component={ChristianMission} />
      <Route exact path="/theexplorer" component={TheExplorer} />
      <Route exact path="/missioncommunication" component={MissionCommunication} />
      <Route exact path="/theovercomers" component={TheOvercomers} />
      <Route exact path="/blessedgeneration" component={BlessedGeneration} />
      <Route exact path="/goodsamaritans" component={GoodSamaritians} />
      <Route exact path="/jehoshaphatarmy" component={JehoshaphatAmry} />
      <Route exact path="/staff" component={Staff} />
      <Route exact path="/staff/:handle" component={StaffDetails} />
      <Route exact path="/blog/:handle" component={Blog} />
      <Route exact path="/blog" component={BlogList} />
      <Route exact path="/blog/preview/:handle" component={PreviewBlog} />
      <Route exact path="/register" component={Member} />
      <Route exact path="/elements" component={Elements} />
      <Route exact path="/look2024" component={LookRegistration} />
      <Route exact path="/livestream" component={LiveStream} />
      <Route exact path="/updatelook/:handle" component={LookUpdate} />
      <Route exact path="/embedded/:handle" component={LookUpdateEmbedded} />
      <Route exact path="/privacypolicy" component={PrivacyPolicy} />
      <Route exact path="/2023budget" component={Budget} />
      <Route exact path="/gallery" component={Gallery} />
    </Switch>
  );
};

export default Routes;
