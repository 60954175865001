import React, { Component } from "react";

export default class NewLetterComponent extends Component {
  render() {
    return (
      <article class="row blog_item">
        <div class="col-md-9">
          <div class="blog_post">
            <div class="blog_details">
              <a
                href={this.props.file}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2> {this.props.title} </h2>
              </a>
              <p>{this.props.description}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="blog_info text-right">
            <ul class="blog_meta list_style">
              <li>
                <a href="# ">
                  {this.props.date} <i class="lnr lnr-calendar-full"></i>
                </a>
              </li>

              <li>
                <a
                  href={this.props.file}
                  class="view_btn button_hover"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read
                </a>
              </li>
            </ul>
          </div>
        </div>
      </article>
    );
  }
}
