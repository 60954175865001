import React, { useState, useEffect } from "react";
import Footer from "../components/FooterC3ma";
import ReactPlayer from "react-player/lazy";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";

export default function C3maDetails(props) {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [member, setMember] = useState({});
  const [fields, setFields] = useState({});
  const [returnMessage, setReturnMessage] = useState({});
  const [voteValue, setVoteValue] = useState(0);

  useEffect(() => {
    const { handle } = props.match.params;
    axios
      .get(`https://fsmission.herokuapp.com/v1/api/c3ma/${handle}`)
      .then((res) => {
        setDetails(res.data);
        setMember(res.data.member);
        setVoteValue(res.data.vote_count);
        setLoading(false);
        console.log("details value", details);
      });
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setFields({ ...fields, [name]: event.target.value, c3ma: details.id });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitLoading(true);
    setReturnMessage({});
    axios
      .post("https://fsmission.herokuapp.com/v1/api/validate_token", fields, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setReturnMessage(response.data);
        setSubmitLoading(false);
        if (response.data.status == "success")
          setVoteValue(voteValue + response.data.vote_value);
      })
      .catch(function (error) {
        if (error.response) {
          const response_error = error.response.data;
          console.log(response_error);
          setSubmitLoading(false);
        }
      });
  };

  return (
    <div>
      <section className="breadcrumb_area br_image_c3ma">
        <div className="container">
          <div className="page-cover text-center">
            <h2 className="page-cover-tittle">C3MA Award</h2>
            <ol className="breadcrumb">
              <li>
                <Link to="/"> Home </Link>
              </li>

              <li>
                <Link to="# ">C3MA </Link>
              </li>

              <li className="active">{details.song_title} </li>
            </ol>
          </div>
        </div>
      </section>

      <section className="blog_area single-post-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="blog_right_sidebar">
                <aside className="single_sidebar_widget post_category_widget">
                  <ul className="list_style cat-list">
                    <li>
                      <a href="#" className="d-flex justify-content-between">
                        <p>Vote Count</p>
                        <p>{voteValue}</p>
                      </a>
                    </li>
                  </ul>
                </aside>
                <br />
                <aside className="single_sidebar_widget author_widget">
                  <img
                    className="author_img rounded-circle"
                    src={member.picture}
                    alt=""
                    style={{
                      width: "150px",
                      // border: "dashed blue 1px",
                    }}
                  />

                  {/* <img className="img-fluid" src={member.picture} alt="" /> */}

                  <div className="br"></div>

                  <h4>{member.name}</h4>
                  {/* <p>by {member.name}</p> */}
                  <div className="social_icon">
                    {member.facebook_profile === "" ? (
                      ""
                    ) : (
                      <a
                        href={member.facebook_profile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa fa-facebook"></i>
                      </a>
                    )}

                    {member.twitter_handle === "" ? (
                      ""
                    ) : (
                      <a
                        href={member.twitter_handle}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa fa-twitter"></i>
                      </a>
                    )}

                    {member.linkedin_profile === "" ? (
                      ""
                    ) : (
                      <a
                        href={member.linkedin_profile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa fa-linkedin"></i>
                      </a>
                    )}
                  </div>
                  <p>{details.about_member}</p>
                </aside>

                <br />
              </div>
            </div>
            <div className="col-lg-8 posts-list">
              <div className="single-post row">
                <div className="col-lg-12 col-md-12 blog_details">
                  {loading ? (
                    <div className="col-lg-12">
                      <center>
                        <Loader
                          type="TailSpin"
                          color="#000000"
                          height={100}
                          width={100}
                        />
                      </center>
                    </div>
                  ) : null}
                  <center>
                    <h4>{details.song_title}</h4>
                  </center>

                  <br />

                  <p className="excert">{details.about_song}</p>

                  <hr />

                  <div className="media post_item">
                    {/* <ReactPlayer
                      url="https://fsmissionadmin.s3-us-west-2.amazonaws.com/media/1._Halleluyah_by_Ifeoluwa_Olatayo.mp3"
                      controls
                      light={true}
                      width={"100%"}
                    /> */}

                    <ReactPlayer
                      url="https://fsmissionadmin.s3-us-west-2.amazonaws.com/media/1._Halleluyah_by_Ifeoluwa_Olatayo.mp3"
                      controls
                      width={"100%"}
                      height={"60px"}
                      controlsList="nodownload"
                      audio={true}
                    />
                  </div>

                  <div className="comment-form">
                    {/* <h4>Leave a Reply</h4> */}
                    <form onSubmit={handleSubmit} method="post">
                      <div className="form-group">
                        {returnMessage && returnMessage.status ? (
                          <div
                            className={
                              returnMessage.status == "success"
                                ? "alert alert-success"
                                : "alert  alert-danger"
                            }
                            role="alert"
                          >
                            {returnMessage.message}
                          </div>
                        ) : null}

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Valid Token"
                          required
                          onChange={handleChange}
                          name="token"
                        />
                      </div>

                      <button className="primary-btn button_hover">
                        {submitLoading ? (
                          <div className="col-lg-12">
                            <center>
                              <Loader
                                type="TailSpin"
                                color="#000000"
                                height={30}
                                width={30}
                              />
                            </center>
                          </div>
                        ) : (
                          "Vote For Candidate"
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
