import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import "../App.css";

export default class SupportScheme extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="Support Scheme" pageName="Support Scheme" />


        <section className="about_area section_gap">
          <div className="container">


         
           
            <div className="row">
              <div className="col-md-12 d_flex">
                <div className="about_content flex">
                  <h5 className="title_color"> Student Mission Support Scheme (SMSS) </h5>
                  <p style={{ textAlign: "justify" }}>
                  Student Mission Support Scheme (SMSS): Regularly mobilize Christian students in groups of 100 /missionary, to support feeding of specific partner missionaries with their campus fellowships/ groups as bases of their operations, mobilize SMC members to initiate SMSS groups on campuses, regularly train SMC members (student members of FSM) on campuses in sustainable management of SMSS for support feeding of Christian missionaries and regularly raise awareness on the need for supporting feeding of Christian missionaries through SMSS. Regularly seek Christian missionaries to be supported by SMSS, monitor the impact of SMSS on partner missionaries, and link individuals and organizations that can support the realization of the task of the Unit.
                  </p>
                  <h5 className="title_color"> Missionary Empowerment Scheme (MES): </h5>
              <p>
 Regularly mobilize support from members and associates to support specific missionaries in vocations they can use to generate fund in order to reduce their dependence on external funding, regularly raise awareness on the need to empower Christian missionaries for self-sustaining, regularly enlist missionaries to be supported, mobilize support and seek partnership from individuals, networks or like-minded organizations in addressing the task of the Unit.
</p>
<h5 className="title_color"> 11 to 1 Mission Support Scheme (for staff of FSM): </h5>
<p>
 Regularly mobilize financial support for sustaining staff members of FSM to reduce their dependence on external funding, regularly raise awareness on the need to empower Christian missionaries for self-sustaining, regularly enlist missionaries to be supported, mobilize support and seek partnership from individuals, networks or like-minded organizations in addressing the task of the Unit. Regularly raise awareness on the need for 11 to 1 Mission Support Scheme; regularly mobilize financial support for sustaining staff members of FSM, link individuals and organizations that can support the realization of the task of the Unit.

     </p>
                </div>
              </div>
              
                
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
