import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

export default class TheExplorer extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="The Explorer" pageName="Project Arm" />

        <section className="about_area section_gap">
          <div className="container">


         
           
            <div className="row">
              <div className="col-md-12 d_flex">
                <div className="about_content flex">
                  <h5 className="title_color">THE EXPLORERS</h5>
                  <p style={{ textAlign: "justify" }}>


                

                  Evangelism and discipleship is essential to reach the unreached and establish them in Christ, including reaching out to children  (using the professional missions approach – educational, medical and entrepreneurial)
    

<br/><br/>Rural missions: Coordinates FSM’s rural missions’ activities and train members the act. Also in collaboration or support to other missionaries/ agencies.
              <br /> <br/>Urban missions: Coordinates FSM’s urban mission activities and train members in the act. Also collaborate or support other missionaries/ agencies.
              <br /><br/>	Discipleship of converts: Discipleship of Student Mission Club (SMC) members by The Wise League (TWL) members. Also in collaboration or support other missionaries/ agencies.





                  
                  </p>
                </div>
              </div>
              
                

              {/* <div className="col-md-12 features_item">
              <p>
              


               
              </p>
          

              </div> */}
            </div>
          </div>
        </section>

       
       
        <Footer />
      </div>
    );
  }
}
