import React, { useState, useEffect } from "react";
import "../App.css";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import BlogComponent from "../components/BlogComponent";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Subscribe from "../components/Subscribe";
import * as qs from "query-string";
import ReactPaginate from "react-paginate";
import {readTime } from "../functions/Functions"

export default function BlogList(props){
  const [blogList, setBlogList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [categoryId, setCategoryId] = useState(null);


  const search = (id) => {
    setLoading(true)
    axios.get("/v1/api/blog", {
        params: {
          category: id,
        },
      })
      .then((res) => {
        setBlogList(res.data.results)
        setNext(res.data.next)
        setPrevious(res.data.previous)
        setCount(res.data.count)
        setPage(res.data.page)
        setPageSize(res.data.page_size)
        setTotalPages(res.data.total_pages)
        setLoading(false)
        setCategoryId(id)
       
      }
      );
  }

  const paginate = (selectedPage) => {
    let page = selectedPage.selected + 1;
    console.log(page);
    setLoading(true)
    axios
      .get("/v1/api/blog", {
        params: {
          page: page,
          category: categoryId,
        },
      })
      .then((res) => {

      setBlogList(res.data.results)
      setNext(res.data.next)
      setPrevious(res.data.previous)
      setCount(res.data.count)
      setPage(res.data.page)
      setPageSize(res.data.page_size)
      setTotalPages(res.data.total_pages)
      setLoading(false)
   
      }
      
      );
  };

  useEffect(() => {
    const handle = qs.parse(props.location.search);
    const page = handle.page ? handle.page : 1;

    axios
      .get(`/v1/api/blog?page=${page}`)
      .then((res) => {
        setBlogList(res.data.results)
        setNext(res.data.next)
        setPrevious(res.data.previous)
        setCount(res.data.count)
        setPage(res.data.page)
        setPageSize(res.data.page_size)
        setTotalPages(res.data.total_pages)
        setLoading(false)

       }
      );

      axios
      .get("/v1/api/post_category")
      .then((res) =>{
        setCategories(res.data)
        setCategoryLoading(false)
      });

  }, []);





    

    return (
      <div className="App">
        <Header />
        <Banner page="Blog And Articles" pageName="Blog" />

        <section className="blog_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog_left_sidebar">
                  <br />
                  <br />
                  {blogList.length === 0 ? (
                    <center>
                      <div className="col-lg-12 col-md-12 blog_details">
                        <h3>No Blog Post Avaialable </h3>
                      </div>
                    </center>
                  ) : null}
                  {loading ? (
                    <div classNameName="col-lg-12">
                      <center>
                        <Loader
                          type="TailSpin"
                          color="#000000"
                          height={100}
                          width={100}
                        />
                      </center>
                    </div>
                  ) : (
                    blogList.map((blog) => (
                      <BlogComponent
                        title={blog.title}
                        content={blog.content}
                        blogId={blog.id}
                        views={blog.views}
                        created={readTime(blog.created_on)}
                        category_name={blog.category_name}
                        slug={blog.slug}
                        description={blog.post_description}
                        image={blog.image}
                      />
                    ))
                  )}

                  <nav className="blog-pagination justify-content-center d-flex">
                    {/* <ul className="pagination">
                      <li className="page-item">
                        <a href="#" className="page-link" aria-label="Previous">
                          <span aria-hidden="true">
                            <span className="lnr lnr-chevron-left"></span>
                          </span>
                        </a>
                      </li>

{[...Array(this.state.totalPages )].map((e, i ) => {
  if ((i + 1) == this.state.page){

    return <li className="page-item active">
    <Link to="#" className="page-link"  onClick = {() => {this.paginate(i + 1)}}>
      {i + 1}
    </Link>
  </li>

  } else {
    return <li className="page-item">
    <Link to="#" className="page-link"  onClick = {() => {this.paginate(i + 1)}}>
      {i + 1}
    </Link>
  </li>

  }
  })}
                      
                     
                      <li className="page-item">
                        <a href="#" className="page-link" aria-label="Next">
                          <span aria-hidden="true">
                            <span className="lnr lnr-chevron-right"></span>
                            
                          </span>
                        </a>
                      </li>
                    </ul> */}

                    <ReactPaginate
                      previousLabel={
                        <li className="page-item">
                          <a
                            href="# "
                            className="page-link"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">
                              <span className="lnr lnr-chevron-left"></span>
                            </span>
                          </a>
                        </li>
                      }
                      nextLabel={
                        <li className="page-item">
                          <a href="# " className="page-link" aria-label="Next">
                            <span aria-hidden="true">
                              <span className="lnr lnr-chevron-right"></span>
                            </span>
                          </a>
                        </li>
                      }
                      breakLabel={"..."}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      activeClassName={"active"}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={paginate}
                      initialPage={0}
                    />
                  </nav>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget search_widget">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Posts"
                      />
                      <span className="input-group-btn">
                        <button className="btn btn-default" type="button">
                          <i className="lnr lnr-magnifier"></i>
                        </button>
                      </span>
                    </div>
                    <div className="br"></div>
                  </aside>

                  {/* <aside className="single_sidebar_widget popular_post_widget">
                    <h3 className="widget_title">Popular Posts</h3>
                    <div className="media post_item">
                      <img src="image/blog/post1.jpg" alt="post" />
                      <div className="media-body">
                        <a href="blog-details.html">
                          <h3>Space The Final Frontier</h3>
                        </a>
                        <p>02 Hours ago</p>
                      </div>
                    </div>
                    <div className="media post_item">
                      <img src="image/blog/post2.jpg" alt="post" />
                      <div className="media-body">
                        <a href="blog-details.html">
                          <h3>The Amazing Hubble</h3>
                        </a>
                        <p>02 Hours ago</p>
                      </div>
                    </div>
                    <div className="media post_item">
                      <img src="image/blog/post3.jpg" alt="post" />
                      <div className="media-body">
                        <a href="blog-details.html">
                          <h3>Astronomy Or Astrology</h3>
                        </a>
                        <p>03 Hours ago</p>
                      </div>
                    </div>
                    <div className="media post_item">
                      <img src="image/blog/post4.jpg" alt="post" />
                      <div className="media-body">
                        <a href="blog-details.html">
                          <h3>Asteroids telescope</h3>
                        </a>
                        <p>01 Hours ago</p>
                      </div>
                    </div>
                    <div className="br"></div>
                  </aside> */}

                  <aside className="single_sidebar_widget post_category_widget">
                    <h4 className="widget_title">Post Catgories</h4>
                    {categoryLoading ? (
                      <div classNameName="col-lg-12">
                        <center>
                          <Loader
                            type="TailSpin"
                            color="#000000"
                            height={60}
                            width={60}
                          />
                        </center>
                      </div>
                    ) : (
                      ""
                    )}
                    <ul className="list_style cat-list">
                      <li>
                        <Link
                          to="#"
                          className="d-flex justify-content-between"
                          onClick={() => {
                            search(null);
                          }}
                        >
                          <p>All </p>
                          <p>{count}</p>
                        </Link>
                      </li>

                      {categories.map((category) => (
                        <li>
                          <Link
                            to="#"
                            className="d-flex justify-content-between"
                            onClick={() => {
                              search(category.id);
                            }}
                          >
                            <p>{category.title}</p>
                            <p>{category.no_post}</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <br />
                    {/* <div className="br"></div> */}
                  </aside>

                  <Subscribe />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  
}
