import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import GoogleMapReact from "google-map-react";

export default class About extends Component {
	static defaultProps = {
		center: {
		  lat: 7.451696,
		  lng: 3.907335,
		},
		zoom: 15,
	  };

	  
  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="About Us" pageName="About" />

        <section className="about_area section_gap">
          <div className="container">
            <div className="section_title text-center">
              <h2>Foundation For Student Mission</h2>
              <p>
                Christian professionals mobilizing and equipping Christian
                students for missions
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 d_flex">
                <div className="about_content flex">
                  <h5 className="title_color text-center">THE VISION AND THE GENESIS</h5>
                  <p style={{ textAlign: "justify" }}>

					  About FSM
The Foundation for Student Mission (FSM) is a network of individual (students and non students from all walks of life) and corporate organization (Churches and mission agencies) focusing on mobilising and equipping Christian students and youths as workforce for missions of the body of Christ.


			  The organisation has two arms: Student Mission Club – SMC (student arm) and The Wise League - TWL (non-student arm). Foundation for Student Mission partners with other mission agencies, mission-oriented churches, and Christian student fellowships or campus groups in her regular activities.

					  <br/>	<br/>
					  The organisation was inaugurated at the University of Agriculture Abeokuta, Nigeria (now Federal University of Agriculture Abeokuta) on May 20, 1995. It is now spread across many cities with institutions of higher learning within and outside of Nigeria. To the glory of God, some former student members of FSM are currently serving as missionaries with different organisations within and outside Nigeria, while others hold the fort in other aspects of missions in their churches or mission agencies. We expect that as students become exposed to multiple mission works, they will find it easier to participate in any of them after graduation.

                  </p>
                </div>
              </div>
              {/* <div className="col-md-6">
                <img src="image/about.jpg" alt="abou_img" />
              </div> */}
            </div>
          </div>
        </section>

        <section className="features_area">
          <div className="row m0">
            <div className="col-md-6 features_item">
              <h3>Our Vision And Objective</h3>
              <p>
                Teaching, encouraging, convincing and involving every Christian
                student in expanding God’s Kingdom through missions to the
                unreached in their generations <br />
                <br />
                We do such by <br />
                A. Mobilizing and equipping Christian students for missions
                <br />
                B. Sustenance of missionary zeal of students on campuses and the
                non-student members in cities where the campuses are located.{" "}
                <br />
                C. Act as support base for many mission agencies and mission
                oriented churches, raising resources for mission works.
              </p>
            </div>

            <div className="col-md-6 features_item">
              <h3>Our Core Values </h3>
              <p>
                A. Exemplary Christian life: You are the light of the world – Mt
                5: 14 (Ye are the light of the world. A city that is set on a
                hill cannot be hid) <br />
                <br />
                B. Professionalism: It is a sin to be a quack! Pr 22:29 (Seest
                thou a man diligent in his business? he shall stand before
                kings; he shall not stand before mean men). <br />
                <br />
                C. Dynamism in Christian missions: Christian Missions, a must –
                Mt 28:19 (Go ye therefore, and teach ALL NATIONS, baptizing them
                in the name of the Father, and of the Son, and of the Holy
                Ghost: make disciples or Christians of all nations)
              </p>
            </div>
          </div>
        </section>

        <section className="team_area section_gap">
          <div className="container">
            <div className="section_title text-center">
              <h2>Membership</h2>
          
              <p> 

              Foundation for Student Mission membership cut 
                across many Christian fellowships and denominations.
</p>
<p>
Members are bound together by a burning desire of seeing the whole world evangelized, starting from their ‘Jerusalems’
</p>
<p> Membership comprises both students <strong> (Student Mission Club )</strong> and non-students <strong> (The Wise League)</strong>  from different mission agencies and mission oriented churches.</p>
<p> Non-students act as facilitators for the student-members in specific area of missions</p>

            
             
             
            </div>
            <div className="row mb_30">
             
              {/* <div className="col-md-6 col-sm-6">
                <div className="team_item">
                  <div className="team_img">
                    <img src="image/team1.jpg" alt="team" />
                  </div>
                  <div className="content">
                    <h3>Philip Goodwin</h3>
                    <p>Chief Pastor</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-6 col-sm-6">
                <div className="team_item">
                  <div className="team_img">
                    <img src="image/team2.jpg" alt="team" />
                  </div>
                  <div className="content">
                    <h3>Duane Lewis</h3>
                    <p>Chief Pastor</p>
                  </div>
                </div>
              </div> */}
            </div>

            <div class="section-top-border">
						<h3 class="mb-30 title_color text-center">STATE / ZONAL CONTACTS IN NIGERIA</h3>
						<div class="progress-table-wrap">
							<div class="progress-table">
								<div class="table-head">
									<div class="serial">#</div>
									<div class="country">State/Zone</div>
									<div class="country">Name</div>
									<div class="visit">Phone number</div>
								</div>
								<div class="table-row">
									<div class="serial">01</div>
									<div class="country"> Ekiti </div>
									<div class="country">Sis. Blessing Omorege </div>
									<div class="visit">
                  08035736054
										
									</div>
								</div>
								<div class="table-row">
									<div class="serial">02</div>
									<div class="country"> Lagos </div>
									<div class="country">Bro. Opeyemi Ojo</div>
									<div class="visit">
                  08062943337
									</div>
								</div>
								<div class="table-row">
									<div class="serial">03</div>
									<div class="country"> Ogun</div>
									<div class="country">Bro.Wole Adigun</div>
									<div class="visit">
                  07064699041
									</div>
								</div>

                <div class="table-row">
									<div class="serial">04</div>
									<div class="country"> Oyo</div>
									<div class="country">Dr. Olufemi AyanfeOluwa</div>
									<div class="visit">
                  08075137957
									</div>
								</div>

                <div class="table-row">
									<div class="serial">05</div>
									<div class="country"> Osun</div>
									<div class="country">Pastor Ayo Okelana</div>
									<div class="visit">
                  08055459052
									</div>
								</div>

                <div class="table-row">
									<div class="serial">06</div>
									<div class="country"> Ondo</div>
									<div class="country">Engr Ayobami Adedokun</div>
									<div class="visit">
                  08068118888
									</div>
								</div>

                <div class="table-row">
									<div class="serial">07</div>
									<div class="country"> Abuja</div>
									<div class="country">Bro.  William Egegie</div>
									<div class="visit">
                  07033130378
									</div>
								</div>

                <div class="table-row">
									<div class="serial">08</div>
									<div class="country"> Plateau</div>
									<div class="country">Bro. Michael Bulkaam</div>
									<div class="visit">
                  08164253018
									</div>
								</div>

                <div class="table-row">
									<div class="serial">09</div>
									<div class="country"> Nassarawa</div>
									<div class="country">Bro. Timothy Babadogo</div>
									<div class="visit">
                  08035093839
									</div>
								</div>

                <div class="table-row">
									<div class="serial">10</div>
									<div class="country"> Kwara</div>
									<div class="country">Dr John Decampus</div>
									<div class="visit">
                  08038296550
									</div>
								</div>

                <div class="table-row">
									<div class="serial">11</div>
									<div class="country"> Northern Liaison officer</div>
									<div class="country">Bro. Seun Bankole</div>
									<div class="visit">
                  08037451485
									</div>
								</div>

                <div class="table-row">
									<div class="serial">12</div>
									<div class="country">Southwest Liaison office</div>
									<div class="country">Bro. Taiwo Babajide</div>
									<div class="visit">
                  08069046644
									</div>
								</div>

                <div class="table-row">
									<div class="serial">13</div>
									<div class="country">Central office</div>
									<div class="country">Dr. Peter Olanrewaju </div>
									<div class="visit">
                  08066372188
									</div>
								</div>

                <div class="table-row">
									<div class="serial">14</div>
									<div class="country">Central Coordinator</div>
									<div class="country">Dr Olugbenga AdeOluwa</div>
									<div class="visit">
                  08035709365
									</div>
								</div>

                
								
							</div>
						</div>

            <h3 class="mb-30 title_color text-center"> CONTACTS OUTSIDE NIGERIA</h3>
            <div class="progress-table-wrap">
							<div class="progress-table">
								<div class="table-head">
									<div class="serial">#</div>
									<div class="country">Countries</div>
									<div class="visit">Name </div>
									<div class="percentage"> Contact </div>
								</div>
								<div class="table-row">
									<div class="serial">01</div>
									<div class="country"> <img src="image/elements/benin.png" alt="flag" />Benin</div>
									<div class="visit">Rev. Michael Okwara</div>
									<div class="percentage">
                  pastormichael03@yahoo.ca


									</div>
								</div>
								<div class="table-row">
									<div class="serial">02</div>
									<div class="country"> <img src="image/elements/sa.png" alt="flag" />South Africa</div>
									<div class="visit">Dr. Adejoke Olufemi</div>
									<div class="percentage">
                  jokephemmy22@yahoo.com
									</div>
								</div>
                <div class="table-row">
									<div class="serial">03</div>
									<div class="country"> <img src="image/elements/gh.png" alt="flag" />Ghana</div>
									<div class="visit">Kweku Enyan Arthur</div>
									<div class="percentage">
									sirarthur9@yahoo.com 
									</div>
								</div>
								<div class="table-row">
									<div class="serial">04</div>
									<div class="country"> <img src="image/elements/eu.jpeg" alt="flag" />Europe</div>
									<div class="visit">Mrs  Moji Odunayo</div>
									<div class="percentage">
									blessedmoji@yahoo.com
									</div>
								</div>

							</div>
						</div>


					</div>
          </div>

		  <div className="section_title text-center">
              <h2>Contact Us</h2>
             
			</div>
		  <div className="container">
            <div style={{ height: "50vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDoeGjd8AdEULasQPIV_8rdnck82vya99k",
                }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                <img
                  lat={7.451696}
                  lng={3.907335}
                  src="image/marker.png"
                  alt="Foundation For Student Mission"
                />

                {/* <AnyReactComponent
            lat={7.451696}
            lng={3.907335}
            text="Foundation For Student Mission"
          /> */}
              </GoogleMapReact>
            </div>

            <br />
            <br />

            <div className="row">
              <div className="col-md-3">
                <div className="contact_info">
                  <div className="info_item">
                    <i className="lnr lnr-home"></i>

                    <p>
                      First Floor, Right wing , No 78/80, beside Elim Plaza. Opp
                      U.I 2nd Gate, Oyo Rd. Ibadan, Oyo State, Nigeria
                    </p>
                  </div>
                  <div className="info_item">
                    <i className="lnr lnr-phone-handset"></i>
                    <h6>
                      <a href="# ">+234 803 570 9365</a>
                    </h6>
                    <p>Mon - Fri: 9 to 5.00pm</p>
                  </div>
                  <div className="info_item">
                    <i className="lnr lnr-envelope"></i>
                    <h6>
                      <a href="# ">centraloffice@fsmission.org</a>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <form
                  className="row contact_form"
                  action="contact_process.php"
                  method="post"
                  id="contactForm"
                  // novalidate="novalidate"
                >
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows="1"
                        placeholder="Enter Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 text-right">
                    <button
                      type="submit"
                      value="submit"
                      className="btn btn_hover btn_hover_two"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>


        </section>

		

        

        <Footer />

		<div id="success" className="modal modal-message fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
                <h2>Thank you</h2>
                <p>Your message is successfully sent...</p>
              </div>
            </div>
          </div>
        </div>

        <div id="error" className="modal modal-message fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
                <h2>Sorry !</h2>
                <p> Something went wrong </p>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}
