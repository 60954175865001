import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Player from "../components/Player";

export default function Registration(props) {
  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [data, setResponse] = useState({});
  const [returnMessage, setReturnMessage] = useState({});
  const [submit, setSubmit] = useState(false);
  const [category, setCategory] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isStudent, setIsStudent] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(false);
  const [selectedAudioFile, setSelectedAudioFile] = useState();
  const [submitAudio, setSubmitAudio] = useState(false);
  const [preview, setPreview] = useState();
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [noAudioMessage, setNoAudioMessage] = useState(false);

  useEffect(() => {
    if (!selectedAudioFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedAudioFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedAudioFile]);

  const onUploadFile = (file) => {
    const formData = new FormData();
    formData.append("audio_recording", file);
    formData.append("name", fields.name);
    formData.append("email", fields.email);
    axios
      .post("https://fsmission.herokuapp.com/v1/api/c3ma_audio", formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setLoadingPercentage(percentage);
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          setResponse(response.data);
          setSelectedAudio(false);
          setSubmitAudio(true);
          setFields({ ...fields, audio: response.data.id });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleUploadChange = (e) => {
    const {
      target: { files },
    } = e;

    setSelectedAudioFile(files[0]);
    setSelectedAudio(true);

    if (files && files[0]) {
      setTimeout(() => {
        onUploadFile(files[0]);
      }, 700);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "application_category") setCategory(event.target.value);

    if (name === "are_you_a_student") {
      event.target.value == "Yes" ? setIsStudent(true) : setIsStudent(false);
    }

    setFields({ ...fields, [name]: event.target.value });
  };

  const handleSubmit = (event) => {
    setSubmitLoading(true);
    setReturnMessage({});

    if (!submitAudio) {
      event.preventDefault();
      setNoAudioMessage(true);
      return;
    }

    if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
      event.preventDefault();

      setReturnMessage({});

      if (category === "Contestant") {
        history.push({
          pathname: "/makepayment",
          fields: fields,
        });
      } else {
        axios
          .post(
            "https://fsmission.herokuapp.com/v1/api/c3ma_application",
            fields,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 201) {
              setResponse(response.data);
              setSubmit(true);
            }
            setSubmitLoading(false);
          })
          .catch(function (error) {
            if (error.response) {
              const response_error = error.response.data;
              setReturnMessage(response_error);
              // console.log(response_error);
              setSubmitLoading(false);
            }
          });
      }
    } else {
      event.preventDefault();
      setIsPhoneValid(false);
      setSubmitLoading(false);
    }
  };

  const selectPhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setFields({ ...fields, phone_number: phone });
  };

  return (
    <div>
      <section className="breadcrumb_area br_image_c3ma">
        <div className="container">
          <div className="page-cover text-center">
            {/* <h2 className="page-cover-tittle">C3MA Award</h2> */}
            <ol className="breadcrumb">
              <li>
                <Link to="/"> Home </Link>
              </li>

              <li>
                <Link to="# ">C3MA </Link>
              </li>

              <li className="active"> Registration </li>
            </ol>

            <div className="row justify-content-center">
              <div className="col-md-6  align-self-center">
                <div className="donate_content ">
                  <h2>
                    C3MA 4.O Audition <br />
                  </h2>

                  {submit && (
                    <div>
                      {data.application_category == "Music Director" && (
                        <div>
                          <h3>Application Submitted Successfuly</h3>

                          <p>
                            Hi <strong>{data.name}</strong> , Your registration
                            is successful. We willl send you a confirmation
                            email on what's next.
                          </p>
                          <br />
                          <a
                            href="https://bit.ly/3tlHXZB"
                            className="btn_hover btn_hover_two"
                          >
                            Join {data.application_category} whatsapp group
                          </a>
                        </div>
                      )}

                      {data.application_category == "Studio Manager" && (
                        <div>
                          <h3>Application Submitted Successfuly</h3>

                          <p>
                            Hi <strong>{data.name}</strong> , Your registration
                            is successful. We willl send you a confirmation
                            email on what's next.
                          </p>
                          <br />
                          <a
                            href="https://bit.ly/3xm5Wud"
                            className="btn_hover btn_hover_two"
                          >
                            Join {data.application_category} whatsapp group
                          </a>
                        </div>
                      )}

                      <br />
                    </div>
                  )}

                  {!submit && (
                    <p>
                      {/* The Christian Missions Music Ministers' Audition is an
                      initiative of the FSM, with a focus of reaching the
                      unreached through gospel music.
                      <br />
                      <br /> */}
                      {/* Please visit{" "}
                      <a href="https://www.fsmission.org/album">
                        fsmission.org/album
                      </a>{" "}
                      for more information and previous albums.
                      <br /> */}
                      Are you a Christian student or youth within 18-30 years?
                      Do you have your originally composed song? Register here!
                      <br />
                      <br />
                      Registration fee:
                      <br />
                      Nigeria: N2,000
                      <br />
                      Ghana: 45 GHS <br />
                      <strong> AUDITION IS FREE!</strong>
                      <br /> <br />
                      Prize:
                      <br />- Audio recording and production
                      <br />- Video recording and production
                      <br /> - Promotion of track and artiste
                      <br />
                      -JE-HOSH-A-PHAT Music Ambassador
                      <br />
                      <br />
                    </p>
                  )}

                  <br />
                </div>

                {!submit && (
                  <div className="donation_form registration">
                    <form onSubmit={handleSubmit} method="post">
                      <hr />
                      <p>
                        Stages of Audition:
                        <br /> 1) 1st Stage: Each contestant register and upload
                        composed song without any instruments.
                        <br /> 2) 2nd Stage: Successful 15 applicants per
                        location (closest) audition city invited to 2nd level
                        contest with their demos and online voting.
                        <br /> 3) 3rd Stage: Best five artistes per audition
                        location present improved demos for final selection of
                        the Judges and online voting. Judges to select Max 10
                        best for MP3.
                        <br /> 4) 4th Stage: Judges select best 5 MP3 songs with
                        online voting for MP4
                      </p>
                      <hr />

                      <h3>Registration </h3>
                      <div className="row">
                        <div className="form-group col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            onChange={handleChange}
                            required
                          />
                          {returnMessage.email ? (
                            <small className="text-danger">
                              Application with this email already exists
                            </small>
                          ) : null}
                        </div>

                        <div className="form-group col-md-12 ">
                          <select
                            className="form-control"
                            name="application_category"
                            onChange={handleChange}
                            required
                          >
                            <option value=""> Choose Your Category </option>
                            <option value="Contestant"> Contestant </option>
                            <option value="Music Director">
                              Music Director
                            </option>
                            <option value="Studio Manager">
                              Studio Manager
                            </option>
                          </select>
                        </div>
                        {category === "Contestant" && (
                          <div className="col-md-12 ">
                            <div className="row">
                              <div className="col-md-12">
                                <hr />

                                <p>Contestant</p>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  name="name"
                                  onChange={handleChange}
                                  required
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name of Institution"
                                  name="name_of_institution"
                                  required
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Fellowship/Church"
                                  required
                                  name="fellowship_or_church"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Age"
                                  required
                                  name="age"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="audition_zone"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Choose Audition Zone</option>
                                  <option value="Jos"> Jos</option>
                                  <option value="Enugu"> Enugu</option>
                                  <option value="Ibadan"> Ibadan</option>
                                  <option value="Calabar"> Calabar</option>
                                  <option value="Accra"> Accra </option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="originally_composed_song"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    Do you have an originally composed song?
                                  </option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="how_you_heard"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    How did you hear about the Audition?
                                  </option>
                                  <option value="Facebook"> Facebook</option>
                                  <option value="Whatsapp"> Whatsapp</option>
                                  <option value="Instagram"> Instagram</option>
                                  <option value="Poster"> Poster</option>
                                  <option value="Friend"> Friend</option>
                                  <option value="Other"> Other</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <br />
                                <p> Preferably WhatsApp Number</p>
                                <PhoneInput
                                  international
                                  required
                                  defaultCountry="NG"
                                  className="form-control"
                                  countryCallingCodeEditable={false}
                                  placeholder="Phone Number (Preferably Whatsapp)"
                                  value={phoneNumber}
                                  onChange={selectPhoneNumber}
                                />
                                {isPhoneValid ? null : (
                                  <small className="text-danger">
                                    Invalid Phone Number
                                  </small>
                                )}

                                {returnMessage.phone_number ? (
                                  <small className="text-danger">
                                    Application with this Phone Number already
                                    exists
                                  </small>
                                ) : null}
                              </div>

                              <div className="form-group col-md-12">
                                {selectedAudio ? (
                                  <div>
                                    <div
                                      style={{
                                        margin: "auto",
                                        width: "30%",
                                        padding: "10px",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={loadingPercentage}
                                        text={`${loadingPercentage}%`}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <label>
                                    Upload your original song <br />
                                    (audio without musical instrument) for the
                                    competition
                                  </label>
                                )}

                                {submitAudio ? (
                                  <Player
                                    url={preview}
                                    controls={true}
                                    width={"100%"}
                                    height={"54px"}
                                    audio={true}
                                    controlsList="nodownload"
                                  />
                                ) : null}

                                <br />
                                <label htmlFor="picture">
                                  <div className="genric-btn btn_hover  medium">
                                    Select Audio File
                                  </div>
                                </label>

                                {noAudioMessage ? (
                                  <small className="text-danger">
                                    Audio Recording not available
                                  </small>
                                ) : null}

                                <input
                                  style={{
                                    visibility: "hidden",
                                    display: "none",
                                  }}
                                  id="picture"
                                  required
                                  type="file"
                                  className="form-control btn_hover btn_hover_two"
                                  placeholder="Audio Recording"
                                  name="audio_recording"
                                  onChange={handleUploadChange}
                                />
                                <hr />
                              </div>
                            </div>

                            <div className="col-md-12 text-center">
                              {submitLoading ? (
                                <Loader
                                  type="TailSpin"
                                  color="#FF0000"
                                  height={50}
                                  width={50}
                                />
                              ) : (
                                <button
                                  type="submit"
                                  className="btn_hover success"
                                  disabled={submitLoading}
                                >
                                  Proceed to Payment
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                        {category === "Music Director" && (
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <hr />

                                <p>Music Director</p>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  name="name"
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="are_you_a_student"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Are you a student ?</option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              {isStudent ? (
                                <div className="form-group col-md-12">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name of Institution"
                                    name="name_of_institution"
                                    required
                                    onChange={handleChange}
                                  />
                                </div>
                              ) : null}

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Fellowship/Church"
                                  required
                                  name="fellowship_or_church"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Age"
                                  required
                                  name="age"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="are_you_a_music_director"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    Are you a music director in your
                                    campus/church?
                                  </option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="accustomed_to_auditioning"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    Are you accustomed to auditioning?
                                  </option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                  <option value="Maybe"> Maybe</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="want_to_audition"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    Do you also want to audition in this
                                    contest?
                                  </option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Years of experience as a music director"
                                  required
                                  name="years_of_experience"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="How many choir members are you directing?"
                                  required
                                  name="no_choir_member"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="be_our_representative"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    Would you like to be our representative in
                                    your campus/church?
                                  </option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <br />
                                <p> Preferably WhatsApp Number</p>
                                <PhoneInput
                                  international
                                  required
                                  defaultCountry="NG"
                                  className="form-control"
                                  countryCallingCodeEditable={false}
                                  placeholder="Phone Number (Preferably Whatsapp)"
                                  value={phoneNumber}
                                  onChange={selectPhoneNumber}
                                />
                                {isPhoneValid ? null : (
                                  <small className="text-danger">
                                    Invalid Phone Number
                                  </small>
                                )}

                                {returnMessage.phone_number ? (
                                  <small className="text-danger">
                                    Application with this Phone Number already
                                    exists
                                  </small>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12 text-center">
                              {submitLoading ? (
                                <Loader
                                  type="TailSpin"
                                  color="#FF0000"
                                  height={50}
                                  width={50}
                                />
                              ) : (
                                <button
                                  type="submit"
                                  className="btn_hover success"
                                  disabled={submitLoading}
                                >
                                  Submit Music Director Application
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                        {category === "Studio Manager" && (
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <hr />
                                <p>Studio Manager</p>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  name="name"
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name of Studio"
                                  name="name_of_studio"
                                  required
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Location"
                                  required
                                  name="location"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Years of experience"
                                  required
                                  name="years_of_experience"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="full_gospel_production"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    Are you into full gospel music production ?
                                  </option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Full music production fee"
                                  required
                                  name="full_production_fee"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Demo recordings fee"
                                  required
                                  name="demo_recording_fee"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="fee_negotiable"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Negotiable?</option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Your Instagram Handle"
                                  name="instagram_handle"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Your Facebook Username)"
                                  name="facebook_username"
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <br />
                                <p> Preferably WhatsApp Number</p>
                                <PhoneInput
                                  international
                                  required
                                  defaultCountry="NG"
                                  className="form-control"
                                  countryCallingCodeEditable={false}
                                  placeholder="Phone Number (Preferably Whatsapp)"
                                  value={phoneNumber}
                                  onChange={selectPhoneNumber}
                                />
                                {isPhoneValid ? null : (
                                  <small className="text-danger">
                                    Invalid Phone Number
                                  </small>
                                )}
                                {returnMessage.phone_number ? (
                                  <small className="text-danger">
                                    Application with this Phone Number already
                                    exists
                                  </small>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12 text-center">
                              {submitLoading ? (
                                <Loader
                                  type="TailSpin"
                                  color="#FF0000"
                                  height={50}
                                  width={50}
                                />
                              ) : (
                                <button
                                  type="submit"
                                  className="btn_hover success"
                                  disabled={submitLoading}
                                >
                                  Submit Studio Manager Application
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
}
