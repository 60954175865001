import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer-area section_gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-5  col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">About FSM</h6>
                <p>
                  Christian professionals mobilizing and equipping Christian
                  students for missions. Teaching, encouraging, convincing and
                  involving every Christian student in expanding God’s Kingdom
                  through missions to the unreached in their generations
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">Navigation Links</h6>
                <div className="row">
                  <div className="col-4">
                    <ul className="list_style">
                      <li>
                        <a href="https://www.fsmission.org/about"> About </a>
                      </li>
                      <li>
                        <a href="https://www.fsmission.org/support">
                          {" "}
                          Support Scheme
                        </a>
                      </li>
                      <li>
                        <a href="https://www.fsmission.org/events"> Events</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-4">
                    <ul className="list_style">
                      <li>
                        <a href="#"> Activities</a>
                      </li>

                      <li>
                        <a href="https://www.fsmission.org/blog"> Blog</a>
                      </li>
                      <li>
                        <a href="https://www.fsmission.org/contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">Newsletter</h6>
                <p>
                  Sign up for our mission regular news letters. It focuses on
                  the professional works of missions and the challenges involved
                </p>
                <div id="mc_embed_signup">
                  <form
                    target="_blank"
                    method="get"
                    className="subscribe_form relative"
                  >
                    <div className="input-group d-flex flex-row">
                      <input
                        name="EMAIL"
                        placeholder="Email Address"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "Email Address")}
                        required=""
                        type="email"
                      />
                      <button className="btn sub-btn">
                        <span className="lnr lnr-location"></span>
                      </button>
                    </div>
                    <div className="mt-10 info"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="border_line"></div>
          <div className="row footer-bottom d-flex justify-content-between align-items-center">
            <p className="col-lg-8 col-sm-8 footer-text m-0">
              Copyright &copy; {new Date().getFullYear()} All rights reserved |{" "}
              <a
                href="https://www.fsmission.org"
                target="_blank"
                style={{ color: "#ff4d4d" }}
              >
                Foundation For Student Mission{" "}
              </a>
            </p>
            <div className="col-lg-4 col-sm-4 footer-social">
              <a
                href="https://facebook.com/FSMission"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                href="https://twitter.com/OfficialFSM_SMC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/fsmissions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a href="# ">
                <i className="fa fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
