import React, { useState } from "react";
import "../App.css";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

export default function Budget() {
 
  return (
    <div className="App">
      <Header />

      <Banner page="2023 Budget" pageName="FSM Budget" />

      <section class="blog_area">
        <div class="container">
          <div class="row">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
              <Viewer fileUrl="doc/Budget.pdf" />
            </Worker>
          </div>
        </div>
        <br />
        <br />
      </section>

      <Footer />
    </div>
  );
}
