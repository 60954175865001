import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import "../App.css";

export default class Oms extends Component {
  // state = {
  //   events: [
  //     {
  //       title: "Training Of Trainers",
  //       eventDate: "11 - 22, January, 2021",
  //       eventVenue: "Our Offices",
  //       location: "Campus/Institution/Online",
  //       imageLink: "image/tot.jpg",
  //     },
      
  //   ],
  // };
  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="OMS" pageName="On campus mission school" />


        <section className="about_area section_gap">
          <div className="container">


         
           
            <div className="row">
              <div className="col-md-12 d_flex">
                <div className="about_content flex">
                  <h5 className="title_color"> On Campus Mission School </h5>
                  <p style={{ textAlign: "justify" }}>


                

                  Fulfilling the Great Commission (Matt 28: 18-20) requires raising disciples in Christian missions as an effective workforce for missions, who should raise other disciples in the same manner.  
    

<br/> <br/>This capacity-building programme aims to train at least 500 missions/evangelism minded Christians (especially those working in academia, research institutes, Youth Corp members, postgraduate students and final year students of institutions of higher learning) in Africa and beyond. We expect these trainees to train at least 20 Christian students within 6 months of their training, to boost the population of the mission workforce in the African countries.
<br/><br/>This training is a project of the Foundation for Student Mission (FSM), a Christian mission’s movement with over two-and-a-half decades of consistent training of Christian students and facilitators as missions workforce for diverse needs of several mission agencies and Churches. FSM desires to see God raising a teeming population of vibrant, apostolic, and dynamic Christian students and youths all over the world that can be part of missions/evangelism teams of their Churches or mission works of their choices.
<br/><br/>THE TRAINING IS A COURSE ADDRESSING:
1. General introduction to missions <br/>
2. Effective Evangelism<br/>
3. Spiritual warfare in missions<br/>
4. Skills/ professions as tools in missions<br/>

            






                  
                  </p>
                </div>
              </div>
              
                

              {/* <div className="col-md-12 features_item">
              <p>
             
               
              </p>
          





              </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
