import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Banner extends Component {
  render() {
    return (
      <section className="breadcrumb_area br_image">
        <div className="container">
          <div className="page-cover text-center">
            <h2 className="page-cover-tittle">{this.props.page}</h2>
            <ol className="breadcrumb">
              <li>
                <Link to="/"> Home </Link>
              </li>

              {this.props.middlePage ? <li>
                <Link to={this.props.middleLink}>{this.props.middlePage } </Link>
              </li> : null}

              
                       


              <li className="active"> {this.props.pageName} </li>
            </ol>
          </div>
        </div>
      </section>
    );
  }
}

Banner.propType = {
  pageName: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export default Banner;
