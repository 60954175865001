export function readTime(time){
    let date = new Date(time);
    return date.toUTCString().slice(0, -12);
  };


export function parseISOString(s){
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export function readTImeEvent(time, start){
  let date = new Date(time);
  if (start) return time === null ? "" : date.toUTCString().slice(0, -12);
  return time === null ? "" : "-" + date.toUTCString().slice(0, -12);
};