import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import DatePicker from "react-datepicker";

import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import "react-datepicker/dist/react-datepicker.css";

const Member = (props) => {
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [fields, setFields] = useState({});
  const [data, setResponse] = useState({});
  const [isStudent, setStudent] = useState(false);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [countryOrigin, setCountryOrigin] = useState("");
  const [birtdayDay, setbirtdayDay] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [preview, setPreview] = useState();
  const [showFacebook, setShowFacebook] = useState(false);
  const [showTwitter, setShowTwitter] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "facebook") {
      event.target.value === "on"
        ? setShowFacebook(true)
        : setShowFacebook(false);
    }
    if (name === "twitter") {
      event.target.value === "on"
        ? setShowTwitter(true)
        : setShowTwitter(false);
    }
    if (name === "linkedin") {
      event.target.value === "on"
        ? setShowLinkedin(true)
        : setShowLinkedin(false);
    }
    if (name === "membership_category") {
      event.target.value === "Non-student"
        ? setStudent(false)
        : setStudent(true);
    }

    if (name === "picture") {
      setSelectedImage(event.target.files[0]);
      setFields({ ...fields, [name]: event.target.files[0] });
    } else {
      setFields({ ...fields, [name]: event.target.value });
    }
  };

  const handleSubmit = (event) => {
    // if fields['phone_number'] ==
    if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
      const formData = new FormData();
      Object.keys(fields).forEach((key) => formData.append(key, fields[key]));

      event.preventDefault();
      setLoading(true);
      axios
        .post("/v1/api/member", formData)
        .then((response) => {
          if (response.status === 201) {
            setResponse(response.data);
            setSubmit(true);
          } else {
            setLoading(false);
          }
        })
        .catch(function (error) {
          if (error.response) {
            const response_error = error.response.data;
            console.log(response_error);
            setLoading(false);
          }
        });
    } else {
      event.preventDefault();
      setIsPhoneValid(false);
      setLoading(false);
    }
  };

  const selectCountry = (val) => {
    setCountry(val);
    setFields({ ...fields, country_of_residence: val });
  };

  const selectRegion = (val) => {
    setRegion(val);
    setFields({ ...fields, state: val });
  };

  const selectCountryOrigin = (val) => {
    setCountryOrigin(val);
    setFields({ ...fields, country_of_origin: val });
  };

  const selectBirthday = (date) => {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    setbirtdayDay(date);
    setFields({ ...fields, birthday: `${day}/${month}` });
  };

  const selectPhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setFields({ ...fields, phone_number: phone });
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedImage) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  return (
    <div>
      <Header />
      <Banner page="Registration" pageName="Membership" />

      <section className="contact_area section_gap whole-wrap">
        {submit ? (
          <div class="container">
            <div class="blog_right_sidebar">
              <aside class="single_sidebar_widget author_widget">
                <div class="section_title text-center">
                  <h2>Memebership Successfuly Submitted</h2>
                  <p>
                    Hi <strong>{data.name}</strong> , Your registration is
                    successful. We willl send you a welcome email to{" "}
                    {data.email_address} on how to get involved .
                    <br />
                    You can check out what we have been up to on our Blog
                  </p>
                  <br />

                  <Link to="/blog" className="btn_hover btn_hover_two">
                    Visit Blog
                  </Link>
                  <br />

                  <Link to="/" className="genric-btn link">
                    Back to Home Page
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        ) : (
          <div className="container">
            <center>
              <h2>Membership Form</h2>
            </center>
            <form
              className="row contact_form"
              onSubmit={handleSubmit}
              method="post"
              // novalidate
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <br />
                    <div className="row">
                      <div className="form-group col-md-12 text-center">
                        <h5>Head Shot Image</h5>
                        {selectedImage ? (
                          <img
                            className=" author_img rounded-circle"
                            style={{
                              width: "180px",
                              border: "dashed blue 1px",
                            }}
                            src={preview}
                            alt=""
                          />
                        ) : (
                          <img
                            style={{
                              width: "180px",
                              border: "dashed blue 1px",
                            }}
                            className=" author_img rounded-circle"
                            src="image/default_profile.jpeg"
                            alt=""
                          />
                        )}
                        <br />
                        <br />
                        <label htmlFor="picture">
                          <div className="genric-btn success-border medium">
                            Select Picture
                          </div>
                        </label>

                        <input
                          style={{ visibility: "hidden", display: "none" }}
                          id="picture"
                          required
                          type="file"
                          className="form-control btn_hover btn_hover_two"
                          placeholder="Picture"
                          name="picture"
                          onChange={handleChange}
                        />
                        <hr />
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="email"
                          className="form-control"
                          name="email_address"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          required
                          name="name"
                          placeholder="Name (Surname first)"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group default-select  col-md-6">
                        <select
                          className="form-control"
                          name="gender"
                          onChange={handleChange}
                          required
                        >
                          <option value=""> Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>

                      <div className="form-group default-select col-md-6">
                        <select
                          className="form-control"
                          name="age"
                          onChange={handleChange}
                          required
                        >
                          <option value=""> Age Range</option>
                          <option value="Below 20">Below 20 Years</option>
                          <option value="21-30">21-30 Years</option>
                          <option value="31-40">31-40 Years</option>
                          <option value="41-50">41-50 Years</option>
                          <option value="51-60">51-60 Years</option>
                          <option value="Above 60">Above 60 Years</option>
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <CountryDropdown
                          defaultOptionLabel="Country Of Residence"
                          className="form-control"
                          name="country_of_residence"
                          value={country}
                          required
                          onChange={(val) => selectCountry(val)}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <RegionDropdown
                          blankOptionLabel="No country selected"
                          defaultOptionLabel="State/Region Of Residence"
                          disableWhenEmpty={true}
                          className="form-control"
                          country={country}
                          value={region}
                          onChange={(val) => selectRegion(val)}
                          required
                          name="state"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <CountryDropdown
                          defaultOptionLabel="Country Of Origin"
                          className="form-control"
                          name="country_of_origin"
                          value={countryOrigin}
                          required
                          onChange={(val) => selectCountryOrigin(val)}
                        />
                      </div>

                      <div className="form-group  col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          placeholder="City of residence"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        {/* <input
                              type="text"
                              className="form-control"
                              required
                              name="phone_number"
                              placeholder="Phone Number (Preferably Whatsapp)"
                              onChange={handleChange}
                            /> */}

                        <PhoneInput
                          international
                          required
                          defaultCountry="NG"
                          className="form-control"
                          countryCallingCodeEditable={false}
                          placeholder="Phone Number (Preferably Whatsapp)"
                          value={phoneNumber}
                          onChange={selectPhoneNumber}
                          error={
                            phoneNumber
                              ? isPossiblePhoneNumber(phoneNumber)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                        {isPhoneValid ? null : (
                          <small className="text-danger">
                            Invalid Phone Number
                          </small>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <select
                          className="form-control"
                          name="membership_category"
                          onChange={handleChange}
                          required
                        >
                          <option value="">Membership Category</option>
                          <option value="Student">Student</option>
                          <option value="Non-student">Non-student</option>
                        </select>
                      </div>

                      {isStudent ? (
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            name="institution"
                            placeholder="Institution"
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {isStudent ? (
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            name="department_and_level"
                            placeholder="Department and course level"
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {!isStudent ? (
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            name="profession"
                            placeholder="Profession"
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {!isStudent ? (
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            name="office_contact"
                            placeholder="Workplace Address"
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Church/fellowship affiiation"
                          name="church_or_fellowship_affiliation"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <DatePicker
                          required
                          dateFormat="dd/MM"
                          className="form-control"
                          selected={birtdayDay}
                          name="birthday"
                          showDayMonthPicker
                          onChange={selectBirthday}
                          dateFormatCalendar="LLLL MM"
                          minDate={new Date(2022, 0)}
                          maxDate={new Date(2022, 11, 31)}
                          placeholderText="Birthday (Day and Month)"
                          // dateFormat="Pp"
                        />
                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <p>
                          Indicate area(s) of interest for developmental project
                        </p>

                        <blockquote className="generic-blockquote">
                          Please note that all members of FSM automatically
                          belongs to both
                          <strong> The Christian Mission</strong> and{" "}
                          <strong>The Explorer </strong>
                          in addition to the optional ones below
                        </blockquote>
                        <div className="single-element-widget">
                          <div className="switch-wrap d-flex justify-content-between">
                            <p>
                              01.{" "}
                              <Link
                                to="/christianmission"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                The Christian Mission
                              </Link>
                            </p>
                            <div className="confirm-checkbox">
                              <input
                                type="checkbox"
                                id="primary-checkbox-1"
                                checked
                              />
                              <label for="primary-checkbox-1"></label>
                            </div>
                          </div>

                          <div className="switch-wrap d-flex justify-content-between">
                            <p>
                              02.{" "}
                              <Link
                                to="/theexplorer"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                The Explorer
                              </Link>
                            </p>
                            <div className="confirm-checkbox">
                              <input
                                type="checkbox"
                                id="primary-checkbox-2"
                                checked
                              />
                              <label for="primary-checkbox-2"></label>
                            </div>
                          </div>

                          <div className="switch-wrap d-flex justify-content-between">
                            <p>
                              03.{" "}
                              <Link
                                to="/missioncommunication"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Mission Communication
                              </Link>{" "}
                            </p>
                            <div className="confirm-checkbox">
                              <input
                                type="checkbox"
                                id="default-checkbox-1"
                                name="project_communication"
                                onChange={handleChange}
                              />
                              <label for="default-checkbox-1"></label>
                            </div>
                          </div>
                          <div className="switch-wrap d-flex justify-content-between">
                            <p>
                              04.{" "}
                              <Link
                                to="/theovercomers"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                The Overcomers
                              </Link>
                            </p>
                            <div className="confirm-checkbox">
                              <input
                                type="checkbox"
                                id="default-checkbox-2"
                                name="project_overcomers"
                                onChange={handleChange}
                              />
                              <label for="default-checkbox-2"></label>
                            </div>
                          </div>

                          <div className="switch-wrap d-flex justify-content-between">
                            <p>
                              05.{" "}
                              <Link
                                to="/blessedgeneration"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Blessed Generation
                              </Link>
                            </p>
                            <div className="confirm-checkbox">
                              <input
                                type="checkbox"
                                id="default-checkbox-3"
                                name="project_blessed_generations"
                                onChange={handleChange}
                              />
                              <label for="default-checkbox-3"></label>
                            </div>
                          </div>

                          <div className="switch-wrap d-flex justify-content-between">
                            <p>
                              06.{" "}
                              <Link
                                to="/goodsamaritans"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Good Samaritians
                              </Link>
                            </p>
                            <div className="confirm-checkbox">
                              <input
                                type="checkbox"
                                id="default-checkbox-4"
                                name="project_good_samaritan"
                                onChange={handleChange}
                              />
                              <label for="default-checkbox-4"></label>
                            </div>
                          </div>
                          <div className="switch-wrap d-flex justify-content-between">
                            <p>
                              07.{" "}
                              <Link
                                to="/jehoshaphatarmy"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                JE-HOSH-A-PHAT Music
                              </Link>
                            </p>
                            <div className="confirm-checkbox">
                              <input
                                type="checkbox"
                                id="default-checkbox-5"
                                name="project_jehoshaphat"
                                onChange={handleChange}
                              />
                              <label for="default-checkbox-5"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p>Preferred means of receiving information</p>

                        <div className="single-element-widget">
                          <div className="switch-wrap d-flex justify-content-between">
                            <p>01. Email</p>
                            <div className="confirm-switch">
                              <input
                                type="checkbox"
                                id="default-switch-1"
                                checked
                                name="email"
                                onChange={handleChange}
                              />
                              <label for="default-switch-1"></label>
                            </div>
                          </div>
                          <div className="switch-wrap d-flex justify-content-between">
                            <p>02. Whatsapp</p>
                            <div className="confirm-switch">
                              <input
                                type="checkbox"
                                id="default-switch-2"
                                name="whatsapp"
                                onChange={handleChange}
                              />
                              <label for="default-switch-2"></label>
                            </div>
                          </div>
                          <div className="switch-wrap d-flex justify-content-between">
                            <p>03. Facebook</p>
                            <div className="confirm-switch">
                              <input
                                type="checkbox"
                                id="default-switch-3"
                                name="facebook"
                                onChange={handleChange}
                              />
                              <label for="default-switch-3"></label>
                            </div>
                          </div>
                          <div className="switch-wrap d-flex justify-content-between">
                            <p>04. Twitter</p>
                            <div className="confirm-switch">
                              <input
                                // checked
                                type="checkbox"
                                id="default-switch-4"
                                name="twitter"
                                onChange={handleChange}
                              />
                              <label for="default-switch-4"></label>
                            </div>
                          </div>

                          <div className="switch-wrap d-flex justify-content-between">
                            <p>05. Linkedin</p>
                            <div className="confirm-switch">
                              <input
                                type="checkbox"
                                id="default-switch-5"
                                name="linkedin"
                                onChange={handleChange}
                              />
                              <label for="default-switch-5"></label>
                            </div>
                          </div>
                        </div>

                        {showFacebook ? (
                          <div className="form-group col-md-12">
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_profile"
                              placeholder="FaceBook Profile"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        ) : null}

                        {showTwitter ? (
                          <div className="form-group col-md-12">
                            <input
                              type="text"
                              className="form-control"
                              name="twitter_handle"
                              placeholder="Twitter Handle"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        ) : null}

                        {showLinkedin ? (
                          <div className="form-group col-md-12">
                            <input
                              type="text"
                              className="form-control"
                              name="linkedin_profile"
                              placeholder="Linkedin Profile"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                {loading ? (
                  <Loader
                    type="TailSpin"
                    color="#FF0000"
                    height={50}
                    width={50}
                  />
                ) : (
                  <button
                    type="submit"
                    className="genric-btn primary e-large "
                    disabled={loading}
                  >
                    Submit Form
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};

export default Member;
