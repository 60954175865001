import React, { useState, useCallback , useEffect } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import { readTImeEvent } from "../functions/Functions";


export default function Gallery() {

    const [loading, setLoading] = useState(true);
    const [gallery, setGallery] = useState([]);

  
  
  useEffect(() => {
      axios.get("/v1/api/event?gallery=yes").then((res) => {
          setGallery(res.data.results);
          setLoading(false)
      });
    }, []);


   



    return (
        <div>
        <Header />
        <Banner page="Image Gallery" pageName="Gallery" />
        
        <section className="gallery_area section_gap">
            <div className="container">


        
            <div className="row">

            { loading ? (<div className="col-lg-12"><center><Loader type="TailSpin" color="#000000" height={100} width={100} /></center></div>): ""  } 

              {gallery.map((event, index) => (

                <div className="col-lg-4">
                <div className="categories_post">
                <Link to={`/events/${event.id}`} >
                            
                      
                    <img src={event.gallery[0].image_thumbnail} alt="post" />
                    <div className="categories_details">
                        <div className="categories_text">

                        <Link to={`/events/${event.id}`} >
                            <h5>{event.title}</h5>
                        </Link>

                            <div className="border_line"></div>
                            <p>{readTImeEvent(event.start_date)}</p>
                        </div>
                    </div>
                    </Link>

                </div>
            </div>

              ))}
                
                </div>




               
            
            
            </div>
        </section>
     

        <Footer />

        </div>
);
}