import React from "react";
import { Switch, Route } from "react-router-dom";
import C3ma from "./c3ma/C3ma";
import C3maDetails from "./c3ma/C3maDetails";
import ContestantRegistration from "./c3ma/ContestantRegistration";
import Registration from "./c3ma/Registration";

const RoutesC3ma = () => {
  return (
    <Switch>
      <Route exact path="/">
        <C3ma />
      </Route>
      <Route exact path="/c3ma/:handle" component={C3maDetails} />
      <Route exact path="/registration" component={Registration} />
      <Route exact path="/makepayment" component={ContestantRegistration} />
    </Switch>
  );
};

export default RoutesC3ma;
