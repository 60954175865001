import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class CarouselItem extends Component {
  render() {
    return (
      <div className="item row" style={{ textAlign: "left" }}>
        <div className="col-lg-8">
          <div className="sermons_image">
            <img src={this.props.image} alt="" />
            <p>{this.props.description}</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="sermons_content">
            <h3 className="title_color">{this.props.title}</h3>
            <br />
            <ul className="list_style sermons_category">
              <li>
                <i className="lnr lnr-user"></i>
                <span>Category: </span>
                <a href="# "> {this.props.category_name}</a>
              </li>
              <li>
                <i className="lnr lnr-eye"></i>
                <span>Blog Post Views : </span> {this.props.views}
              </li>
              <li>
                <i className="lnr lnr-calendar-full"></i>
                <span>Date:</span> {this.props.created}
              </li>
            </ul>

            <Link
              to={`/blog/${this.props.slug}`}
              className="btn_hover btn_hover_two"
            >
              View More Details
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
