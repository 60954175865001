import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import "../App.css";
import axios from "axios";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Loader from "react-loader-spinner";

export default class Album extends Component {
  state = {
    albumList: [],
    loading: true,
  };

  componentDidMount() {
    axios
      .get("https://www.fsmission.org/v1/api/album")
      .then((res) => this.setState({ albumList: res.data, loading: false }));
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="Music Album Project" pageName="Album" />

        <section className="blog_categorie_area">
          <div className="container">
            <div className="row">
              {this.state.loading ? (
                <div className="col-lg-12">
                  <center>
                    <Loader
                      type="TailSpin"
                      color="#000000"
                      height={100}
                      width={100}
                    />
                  </center>
                </div>
              ) : (
                ""
              )}

              {this.state.albumList.reverse().map((album) => (
                <div className="col-lg-4">
                  <div className="categories_post">
                    <Link to={`/album/${album.slug}`}>
                      <LazyLoad height={200} once>
                        <img src={album.image} alt="post" />
                      </LazyLoad>

                      <div className="categories_details">
                        <div className="categories_text">
                          <div className="border_line"></div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="border_line_album"></div>

                  <ul className="list_style sermons_category event_category">
                    <li>
                      <h2 style={titleStyle}>{album.title} </h2>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>

        <br />
        <br />

        <Footer />
      </div>
    );
  }
}

const titleStyle = {
  background: "#333",
  color: "#fff",
  textAlign: "center",
  fontSize: "12px",
  padding: "8px",
};
