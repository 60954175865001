import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class BlogComponent extends Component {
  render() {
    return (
      <article class="row blog_item">
        <div class="col-md-3">
          <div class="blog_info text-right">
            <ul class="blog_meta list_style">
              <li>
                <a href="# ">
                  {this.props.created}
                  <i class="lnr lnr-calendar-full"></i>
                </a>
              </li>
              <li>
                <a href="# ">
                  {this.props.views}
                  <i class="lnr lnr-eye"></i>
                </a>
              </li>
              <li>
                <a href="# ">
                  {this.props.category_name}
                  <i class="lnr lnr-tag"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-9">
          <div class="blog_post">
            {/* <img src="image/blog/main-blog/m-blog-1.jpg" alt="" /> */}
            {this.props.image ? <img src={this.props.image} alt="" /> : null}

            <div class="blog_details">
              <Link to={`/blog/${this.props.slug}`}>
                <h2>{this.props.title}</h2>
              </Link>

              <p>
                {this.props.description}

                {/* MCSE boot camps have its supporters and its detractors. Some
                people do not understand why you should have to spend money on
                boot camp when you can get the MCSE study materials yourself at
                a fraction. */}
              </p>
              <Link
                to={`/blog/${this.props.slug}`}
                className="view_btn button_hover"
              >
                View More
              </Link>
            </div>
          </div>
        </div>
      </article>
    );
  }
}
