import React, { Component } from "react";
import Player from "./Player";

export default class PlayListComponent extends Component {
  state = {
    status: {},
  };

  render() {
    return (
      <div>
        {this.props.media.map((music) => (
          <div>
            <br />

            {music.media_type === "Audio" ? (
              <div class="media post_item">
                <div className="media-body">
                  <a href="# ">
                    <h3>{music.title}</h3>
                  </a>
                  <p>{music.artist}</p>
                </div>
                <Player
                  url={music.file}
                  controls={true}
                  width={"300px"}
                  height={"54px"}
                  audio={true}
                  controlsList="nodownload"
                />
              </div>
            ) : (
              <div>
                {/* <div className="text-right">
                  <a
                    className="btn btn_hover"
                    href="https://drive.google.com/u/0/uc?export=download&confirm=9Ib5&id=1Fl654AYN-47y8QAZ2l2f7F363kF78RqX"
                  >
                    Download Video
                  </a>
                  <br />
                  <br />
                </div> */}

                <div className="media post_item">
                  <Player
                    url={music.link}
                    controls
                    light={true}
                    width={"100%"}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
