import React, { Component } from "react";
import "../App.css";

import { PaystackConsumer } from "react-paystack";
import Loader from "react-loader-spinner";
import { withAlert } from "react-alert";

class DonationFormComponent extends Component {
  state = {
    config_email: "",
    config_amount: 20000,
    status: {},
    send_reminder: false,
    fields: {},
    loading: false,
  };

  alert = this.props.alert;

  handleChangeAmount(e) {
    this.setState({ config_amount: e.target.value });
  }

  handleChangeEmail(e) {
    this.setState({ config_email: e.target.value });
  }

  showReminder(e) {
    this.setState({ send_reminder: true });
  }

  handleChange = (event) => {
    let fields = this.state.fields;
    const name = event.target.name;
    fields[name] = event.target.value;
    this.setState({ fields });
  };

  handleSubmit = (event) => {
    this.state.fields["album"] = this.props.albumID;
    event.preventDefault();
    this.setState({
      loading: true,
    });
  };

  render() {
    // start paystack
    // you can call this function anything
    const handleSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      console.log(reference);
      this.props.parentFunction("Donation successful");
    };

    // you can call this function anything
    const handleClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      this.alert.show("Donation Cancelled", {
        type: "error",
      });
    };

    const componentProps = {
      ...{
        reference: new Date().getTime(),
        amount: this.state.config_amount * 100,
        email: this.state.config_email,
        publicKey: "pk_live_d278bdeaf49155161704db962483042078f8879e",
      },
      text: "Paystack Button Implementation",
      onSuccess: (reference) => handleSuccess(reference),
      onClose: handleClose,
    };

    return (
      <div>
        <div className="donation_form">
          {/* donate part  */}
          {!this.state.send_reminder ? (
            <div>
              <p> {this.props.donationMessage} </p>
              {/* <h5>How much would you like to donate  ?</h5> */}

              <br />
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Amount"
                  onChange={(e) => {
                    this.handleChangeAmount(e);
                  }}
                />
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => {
                    this.handleChangeEmail(e);
                  }}
                />
              </div>

              <PaystackConsumer {...componentProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn_hover btn_hover_two"
                    onClick={() =>
                      initializePayment(handleSuccess, handleClose)
                    }
                  >
                    Donate and download now
                  </button>
                )}
              </PaystackConsumer>

              <br />
              <br />
              <button
                className="btn_hover view_btn "
                onClick={(e) => {
                  this.showReminder(e);
                }}
              >
                Download and donate later
              </button>
            </div>
          ) : (
            <div>
              <br />
              <p> Send Donation Reminder </p>

              <form className="row" onSubmit={this.handleSubmit}>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name "
                      name="full_name"
                      value={this.state.fields["full_name"]}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phone_number"
                      value={this.state.fields["phone_number"]}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={this.state.fields["emaill"]}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* <div className="form-group">
                <select
                   
                    className="form-control"
                    placeholder="Email">

                        <option>Daily</option>
                        <option>Weekly </option>
                        <option>Montly</option>


                    </select>
               
                </div> */}

                {/* <button className="btn_hover btn_hover_two " onClick={(e) => {this.props.parentFunction()}}>
        Download 
        </button> */}

                <div className="col-md-12 ">
                  {this.state.loading ? (
                    <Loader
                      type="TailSpin"
                      color="#FF0000"
                      height={50}
                      width={50}
                    />
                  ) : (
                    <button
                      type="submit"
                      value="submit"
                      className="btn btn_hover btn_hover_two"
                      disabled={this.state.loading}
                    >
                      {" "}
                      Download
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="border_line_album"></div>
        <br />

        {/* <h3 class="widget_title">Music List</h3> */}
        {/* <div class="media post_item">
                          <img src="image/blog/post1.jpg" alt="post" />
                          <div class="media-body">
                            <a href="blog-details.html">
                              <h3>Space The Final Frontier</h3>
                            </a>
                            <p>02 Hours ago</p>
                          </div>
                        </div> */}
      </div>
    );
  }
}

export default withAlert()(DonationFormComponent);
