import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Countdown from 'react-countdown';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class NextEvent extends Component {

  state = {
    event: {},
    loading: true
   
  };


  componentDidMount() {
    axios
      .get("https://fsmission.herokuapp.com/v1/api/event?time=current&page_size=10000")
      .then((res) => {
         let eventT =  res.data.results[res.data.results.length - 1]; 
         if (eventT){
             this.setState({ 
           event: eventT,    
           loading: false,
           noEvent: false, 
          
          }); 

         } else {
             this.setState({ 
             noEvent: true
          }); 

         }
        }
          
         );   
}


  render() {

   
    const Completionist = () => <span>You are good to go!</span>;
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Completionist />;
      } else {
        // Render a countdown
        
        return <div id="timer" className="timer">
                <div className="timer__section days">
                  <div className="timer__number">{ this.state.loading ? (<Loader type="TailSpin" color="#FFFFFF" height={40} width={40} />): days  }</div>
                  <div className="timer__label">days</div>
                </div>
                <div className="timer__section hours">
                  <div className="timer__number">{ this.state.loading ? (<Loader type="TailSpin" color="#FFFFFF" height={40} width={40} />): hours  }</div>
                  <div className="timer__label">Hours</div>
                </div>
                <div className="timer__section minutes">
                  <div className="timer__number">{ this.state.loading ? (<Loader type="TailSpin" color="#FFFFFF" height={40} width={40} />): minutes  }</div>
                  <div className="timer__label">Minutes</div>
                </div>
                <div className="timer__section seconds">
                  <div className="timer__number">{ this.state.loading ? (<Loader type="TailSpin" color="#FFFFFF" height={40} width={40} />): seconds  }</div>
                  <div className="timer__label">seconds</div>
                </div>
              </div>
      }
    };

    const readTimeNext = (time, start) => {
      let date = new Date(time);
      if (start) return time == null ? "" : date.toUTCString().slice(0, -12)
      return time == null ? "" : "-" + date.toUTCString().slice(0, -12)

    }

    const readTime = (time) => {
      let date = new Date(time);
      return date.toUTCString().slice(-12)
      

    }

  
    return (
      <section className="event_date_area">
        <div className="container">


        { this.state.noEvent ? (
          <div className="row">
          <div className="col-md-12 ">
          <center><h4>No Upcoming Event </h4></center>
            
          </div></div>



        ):(


          <div className="row">
            <div className="col-md-6 d_flex">
            
            
              <div className="evet_location flex">
                { this.state.loading ? (<center> <Loader type="TailSpin" color="#FFFFFF" height={60} width={60} /></center>): (

<div>
<h3> {this.state.event.title } </h3>
<p>
  <span className="lnr lnr-calendar-full"></span>{readTimeNext(this.state.event.start_date, true)} {readTimeNext(this.state.event.end_date, false)}
</p>
<p>

  <span className="lnr lnr-clock"></span> { readTime(this.state.event.start_date)}
</p>
<br/>


  <Link to={`/events/${this.state.event.id}`}   className="btn_hover view_btn">
          View Details
            </Link>


</div>

                )}
              
              


              </div>
            </div>
            <div className="col-md-6 event_time">
              <h4>Our Next Event Starts in</h4>
              <Countdown
    date={new Date(this.state.event.start_date)}
    renderer={renderer}
  />



              
            </div>
          </div>)}




        </div>
      </section>
    );
  }
}
