import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import NextEvent from "../components/NextEvent";
import EventComponent from "../components/EventComponent";
import "../App.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import { readTImeEvent } from "../functions/Functions";
import ReactPaginate from "react-paginate";
import * as qs from "query-string";


export default function Events(props){
  const [events, setEvents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  


  useEffect(() => {
    const handle = qs.parse(props.location.search);
    const thisPage = handle.page ? handle.page : 1;

    axios
      .get(`/v1/api/event?page=${thisPage}`)
      .then((res) => {
        setEvents(res.data.results)
        setTotalPages(res.data.total_pages)
        setLoading(false)
       }
      );


  }, []);


  const paginate = (selectedPage) => {
    let currentPage = selectedPage.selected + 1;
    setLoading(true)
    axios
      .get("/v1/api/event", {
        params: {
          page: currentPage,
        },
      })
      .then((res) => {

        setEvents(res.data.results)
        setTotalPages(res.data.total_pages)
        setLoading(false)
   
      }
      
      );
  };


 



  


    return (
      <div className="App">
        <Header />
        <Banner page="Upcoming Events" pageName="Events"  />

        <NextEvent />

        <section className="event_blog_area section_gap">
          <div className="container">
            <div className="row event_two">
            { loading ? (<div className="col-lg-12"><center><Loader type="TailSpin" color="#000000" height={100} width={100} /></center></div>): ""  }
              {events.map((event) => (
                <EventComponent
                  title={event.title}
                  startDate={readTImeEvent(event.start_date, true)}
                  endDate={readTImeEvent(event.end_date, false)}
                  eventVenue={event.event_venue}
                  location={event.event_location}
                  imageLink={event.image}
                  imageThumb={event.image_thumbnail}
                  event = {event}
                  eventID = {event.id}
                />
              ))}
            </div>

            {(<nav className="blog-pagination justify-content-center d-flex">
                   
                    <ReactPaginate
                      previousLabel={
                        <li className="page-item">
                          <a
                            href="# "
                            className="page-link"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">
                              <span className="lnr lnr-chevron-left"></span>
                            </span>
                          </a>
                        </li>
                      }
                      nextLabel={
                        <li className="page-item">
                          <a href="# " className="page-link" aria-label="Next">
                            <span aria-hidden="true">
                              <span className="lnr lnr-chevron-right"></span>
                            </span>
                          </a>
                        </li>
                      }
                      breakLabel={"..."}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      activeClassName={"active"}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={paginate}
                      initialPage={0}
                     
                    />
                  </nav>)}
          </div>
        </section>
        <Footer />
      </div>
    );
  
}
