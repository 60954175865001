import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import GoogleMapReact from "google-map-react";

export default class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 7.451696,
      lng: 3.907335,
    },
    zoom: 15,
  };

  render() {
    // const mapDetails = {
    //   lat: 40.701083,
    //   lng: -74.1522848,
    //   zoom: 13,
    //   info: "PO Box CT16122 Collins Street West, Victoria 8007, Australia.",
    //   markerLat: 40.701083,
    //   markerLon: -74.1522848,
    // };

    return (
      <div className="App">
        <Header />
        <Banner page="Contact Us" pageName="Contact" />
        <section className="contact_area section_gap">
          <div className="container">
            <div style={{ height: "50vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDoeGjd8AdEULasQPIV_8rdnck82vya99k",
                }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                <img
                  lat={7.451696}
                  lng={3.907335}
                  src="image/marker.png"
                  alt="Foundation For Student Mission"
                />

                {/* <AnyReactComponent
            lat={7.451696}
            lng={3.907335}
            text="Foundation For Student Mission"
          /> */}
              </GoogleMapReact>
            </div>

            <br />
            <br />

            <div className="row">
              <div className="col-md-3">
                <div className="contact_info">
                  <div className="info_item">
                    <i className="lnr lnr-home"></i>

                    <p>
                      First Floor, Right wing , No 78/80, beside Elim Plaza. Opp
                      U.I 2nd Gate, Oyo Rd. Ibadan, Oyo State, Nigeria
                    </p>
                  </div>
                  <div className="info_item">
                    <i className="lnr lnr-phone-handset"></i>
                    <h6>
                      <a href="# ">+234 803 570 9365</a>
                    </h6>
                    <p>Mon - Fri: 9 to 5.00pm</p>
                  </div>
                  <div className="info_item">
                    <i className="lnr lnr-envelope"></i>
                    <h6>
                      <a href="# ">centraloffice@fsmission.org</a>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <form
                  className="row contact_form"
                  action="contact_process.php"
                  method="post"
                  id="contactForm"
                  // novalidate="novalidate"
                >
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows="1"
                        placeholder="Enter Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 text-right">
                    <button
                      type="submit"
                      value="submit"
                      className="btn btn_hover btn_hover_two"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer />

        <div id="success" className="modal modal-message fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
                <h2>Thank you</h2>
                <p>Your message is successfully sent...</p>
              </div>
            </div>
          </div>
        </div>

        <div id="error" className="modal modal-message fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close"></i>
                </button>
                <h2>Sorry !</h2>
                <p> Something went wrong </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
