import React, { Component } from "react";
import "../App.css";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import StaffComponent from "../components/StaffComponent";
import axios from "axios";
import Loader from "react-loader-spinner";

export default class Staff extends Component {
  state = {
    profiles: [],
    loading: true,
  };

  componentDidMount() {
    axios
      .get("/v1/api/profile")
      .then((res) => this.setState({ profiles: res.data, loading: false }));
  }

  render() {
    const truncate = (reference) => {
      return reference.length > 70
        ? reference.substring(0, 70) + "..."
        : reference;
    };

    return (
      <div className="App">
        <Header />

        <Banner page="Staff" pageName="Staff" />

        <section class="blog_area">
          <div class="container">
            <div class="row">
              {this.state.loading ? (
                <div className="col-lg-12">
                  <center>
                    <Loader
                      type="TailSpin"
                      color="#000000"
                      height={100}
                      width={100}
                    />
                  </center>
                </div>
              ) : (
                ""
              )}

              {this.state.profiles.map((profile) => (
                <StaffComponent
                  fullName={profile.full_name}
                  position={profile.position}
                  about={truncate(profile.about)}
                  imageLink={profile.image}
                  staffId={profile.id}
                  facebook={profile.facebook_link}
                  twitter={profile.twitter_link}
                  instagram={profile.instagram_link}
                />
              ))}
            </div>
          </div>
          <br />
          <br />
        </section>

        <Footer />
      </div>
    );
  }
}
