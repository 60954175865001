import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class StaffComponent extends Component {
  render() {
    return (
      <div class="col-lg-4">
        <br />
        <div class="blog_right_sidebar staff_view">
          {/* <aside class="single_sidebar_widget ads_widget">
                    <a href="#">
                      <img class="img-fluid" src="image/blog/add.jpg" alt="" />
                    </a>
                  
                  </aside> */}

          <aside class="single_sidebar_widget author_widget">
            <img
              class="author_img rounded-circle"
              src={this.props.imageLink}
              alt=""
            />
            <br />
            <br />
            <h4> {this.props.fullName}</h4>
            <p>{this.props.position}</p>
            <div class="social_icon">
              {this.props.facebook === "" ? (
                ""
              ) : (
                <a href={this.props.facebook}>
                  <i class="fa fa-facebook"></i>
                </a>
              )}
              {/* <a href={ this.props.facebook == "" ? "#" :  this.props.facebook}>
                        <i class="fa fa-facebook"></i>
                      </a> */}
              {this.props.twitter === "" ? (
                ""
              ) : (
                <a href={this.props.twitter}>
                  <i class="fa fa-twitter"></i>
                </a>
              )}

              {this.props.instagram === "" ? (
                ""
              ) : (
                <a href={this.props.instagram}>
                  <i class="fa fa-instagram"></i>
                </a>
              )}
            </div>
            <p>
              <p>{this.props.about}</p>
            </p>
            <br />
            {/* <div class="br"></div> */}
            <Link to={`/staff/${this.props.staffId}`} className="btn_hover">
              View Details
            </Link>
          </aside>
        </div>
      </div>
    );
  }
}
