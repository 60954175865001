import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

export default class Look extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Banner page="Look Conference" pageName="Look" />

        <section className="about_area section_gap">
          <div className="container">


         
           
            <div className="row">
              <div className="col-md-12 d_flex">
                <div className="about_content flex">
                  <h5 className="title_color"> LOOK Yearly Conference</h5>
                  <p style={{ textAlign: "justify" }}>

                 


                

                  This is an annual missions and capacity building conference of Christian students, professionals and other like-minded believers from all walks of life






                  
                  </p>
                </div>
              </div>
              
                

              {/* <div className="col-md-12 features_item">
              <p>
              <br/>Information and Communication Technology (ICT): Regularly develop online tools for missions, train members of the team on specific areas of ICT for effective contributions to missions, and raise awareness of the need for ICT in missions. Also, coordinate reaching out via website and social media and provide ICT supports for partner missionaries of FSM. Link up with individuals and organizations that can support realization of the task of the Unit. Development/management of websites and Apps for FSM activities.
              <br /> <br/>Publication/editorial (PE): Regularly raise awareness on the need for publications in missions and support missionaries without capacity on newsletter, tracts writing, and other publication processes. Link up with individuals and organizations that can support realization of the task of the Unit. Train members on writing skills (tracts, books, etc.), services within FSM; newsletters, magazines, websites, publication, etc. 
              <br /><br/>	Research (R): Regularly research into new areas of FSM’s operations/ improvement implementing activities and publish in appropriate platforms of FSM, including the website, awareness of the need for research in missions. Regularly train members on research tools in Christian missions. Alike, regularly collaborate with other mission agencies in discovering unreached people groups, needs of missions or other related issues. Link up with individuals and organizations that can support realization of the task of the Unit.
              <br/><br/>Audio-visuals (AV): Regularly train members on audio-visual recording skills, raise awareness on the need for audio-visuals in missions, support missionary partners of FSM on audio-visuals and link individuals and organizations that can support realization of the task of the Unit. Update of media appropriate pages of our website and social media platforms with photographs and video clips of FSM activities. Recording and documentation of FSM activities.
              <br/><br/> Library Services (LS): Regularly raise awareness of the need for learning resources in missions. Also, lending of books to missionaries or assistance in developing their libraries, regularly share information about new books uploaded on the Library resources’ page. Link individuals and organizations that can support realization of the task of the Unit. Development of library (physical and virtual) for FSM, train members of the team on the collection of Christian literature and encouraging act of reading among FSM members, regularly update Library Resources page of FSM on our website with useful books, videos, presentations, weblinks, etc. addressing core values of FSM.

               
              </p>
          





              </div> */}
            </div>
          </div>
        </section>

       
       
        <Footer />
      </div>
    );
  }
}
