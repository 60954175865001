import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";

export default function Footer(){
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [returnMessage, setReturnMessage] = useState({});
  const [data, setResponse] = useState({});
  const [submit, setSubmit] = useState(false);


  const handleChange = (event) => {
    const name = event.target.name;
    setFields({ ...fields, [name]: event.target.value });
  };



  const handleSubmit = (event) => {
      setSubmitLoading(true);
      event.preventDefault();
      setReturnMessage({});

      axios
        .post("/v1/api/subscriber", fields, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            setResponse(response.data);
            setSubmit(true);
          }
          setSubmitLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            const response_error = error.response.data;
            setReturnMessage(response_error);
            console.log(response_error);
            setSubmitLoading(false);
          }
        });
    
  };


    return (
      <footer className="footer-area section_gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-5  col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">About FSM</h6>
                <p>
                  Christian professionals mobilizing and equipping Christian
                  students for missions. Teaching, encouraging, convincing and
                  involving every Christian student in expanding God’s Kingdom
                  through missions to the unreached in their generations
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">Navigation Links</h6>
                <div className="row">
                  <div className="col-8">
                    <ul className="list_style">
                      <li>
                        <Link to="/about"> About </Link>
                      </li>
                      <li>
                        <Link to="/support"> Support Scheme</Link>
                      </li>
                      <li>
                        <Link to="/events"> Events</Link>
                      </li>

                      <li>
                        <Link to="#"> Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">Newsletter</h6>
                <p>
                  Sign up for our mission regular news letters. It focuses on
                  the professional works of missions and the challenges involved
                </p>
                <div id="mc_embed_signup">
                  <form
                   onSubmit={handleSubmit} method="post"
                    
                    className="subscribe_form relative"
                  >
                    <div className="input-group d-flex flex-row">
                      <input
                        name="email"
                        placeholder="Email Address"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "Email Address")}
                        type="email"
                        onChange={handleChange}
                        required
                      />

                    {returnMessage.email ? (
                            <small className="text-danger">
                              Subscriber with this email already exists
                            </small>
                          ) : null}





                      


                      {submitLoading ? (
                            <Loader
                            className="btn sub-btn" 
                              type="TailSpin"
                              color="#FFFFFF"
                              height={20}
                              width={20}
                            />
                          ) : (
                           
<button className="btn sub-btn"  disabled={submitLoading}  type="submit">
<span className="lnr lnr-location"></span>
</button>

                          )}


                    </div>
                    <div className="mt-10 success"> 

                    {submit && (<p style={{color: "white"}}> 
                      Successfully subscribed  <strong>{data.name}</strong> 
                      </p>)}
                    
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="border_line"></div>
          <div className="row footer-bottom d-flex justify-content-between align-items-center">
            <p className="col-lg-8 col-sm-8 footer-text m-0">
              Copyright &copy; {new Date().getFullYear()} All rights reserved |{" "}
              <Link to="#" target="_blank" style={{ color: "#ff4d4d" }}>
                Foundation For Student Mission{" "}
              </Link>
            </p>
            <div className="col-lg-4 col-sm-4 footer-social">
              <a
                href="https://facebook.com/FSMission"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                href="https://twitter.com/OfficialFSM_SMC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/fsmissions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a href="# ">
                <i className="fa fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  
}
