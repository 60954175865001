import React, { useState, useEffect } from "react";
import Footer from "../components/FooterC3ma";
import HeaderC3ma from "../components/HeaderC3ma";
import { Link } from "react-router-dom";
import "../App.css";
import { PaystackButton } from "react-paystack";
import axios from "axios";

import Loader from "react-loader-spinner";
import Banner from "../components/Banner";
import C3maComponent from "../components/C3maComponent";

export default function C3ma() {
  const [loading, setLoading] = useState(true);
  const [c3maProfile, setC3maProfile] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    axios.get("https://fsmission.herokuapp.com/v1/api/c3ma").then((res) => {
      setC3maProfile(res.data);
      setLoading(false);
    });
  }, []);

  const handleSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
  };

  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log('closed')
  };

  const handleChange = (event) => {
    const name = event.target.name;
    // setFields({ ...fields, [name]: event.target.value });
    if (name === "name") setName(event.target.value);
    if (name === "email") setEmail(event.target.value);
    if (name === "quantity") setAmount(event.target.value * 100 * 50);
  };

  const config = {
    text: "Purchase",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
    publicKey: "pk_live_d278bdeaf49155161704db962483042078f8879e",
  };

  const componentProps = {
    ...config,
    reference: new Date().getTime().toString(),
    amount: amount,
    email: email,
    metadata: { page: "c3ma", name: name },
  };

  return (
    <div className="App">
      {/* <HeaderC3ma /> */}
      <section className="banner_area_c3ma d-flex text-center">
        <div className="container align-self-center">
          <Link className="navbar-brand logo_h" to="/">
            <img src="/image/c3ma_logo.png" alt="" />
          </Link>
          <br />
          <br />
          <br />
          <div className="row">
            <div className="col-md-6">
              <div className="banner_content">
                {/* <h6>Who Created us</h6> */}
                <h1>C3MA 4.0 Audition</h1>
                <p>
                  An initiative of the <br />{" "}
                  <strong> Foundation for Student Mission </strong> through the{" "}
                  <strong>JE-HOSH-A-PHAT Music </strong>
                  Project Arm <br />
                  which discovers, trains, promotes and produces musical albums
                  of talented Christian students/youths <br />
                  {/* from different denominations for mission
                  outreaches */}
                </p>

                <Link to="/registration" className="btn_hover btn_hover_two">
                  Register To Participate
                </Link>

                {/* <a
                    href="http://bit.ly/FSMREGISTRATIONLINK"
                    className="btn_hover btn_hover_two"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Become A Member
                  </a> */}
              </div>
              <br />
            </div>
            <div class="col-md-6">
              <div class="donation_form">
                <h3>Purchase Voting Token(s) </h3>
                <div class="form-group">
                  <select
                    className="form-control"
                    name="quantity"
                    onChange={handleChange}
                    required
                  >
                    <option value=""> Quantity </option>
                    {[...Array(30)].map((x, i) => (
                      <option value={i + 1}>{i + 1}</option>
                    ))}
                  </select>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Amount"
                    value={amount / 100}
                    name="amount"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    name="email"
                    required
                    onChange={handleChange}
                  />
                </div>

                <PaystackButton
                  {...componentProps}
                  className="btn_hover btn_hover_two"
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </section>

      <section class="blog_area">
        <div class="container">
          <div class="section_title text-center">
            <h2>Participants</h2>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <br />
              <div class="blog_right_sidebar staff_view">
                <aside class="single_sidebar_widget author_widget">
                  <br />
                  <h4> Voting will commence soon </h4>
                  <br />
                  <p> You can check out what we have been up to on our Blog </p>

                  <br />
                  <a
                    href="https://www.fsmission.org/blog"
                    className="btn_hover btn_hover_two"
                  >
                    Visit Blog
                  </a>
                  <br />
                </aside>
              </div>
            </div>

            {loading ? (
              <div className="col-lg-12">
                <center>
                  <Loader
                    type="TailSpin"
                    color="#000000"
                    height={100}
                    width={100}
                  />
                </center>
              </div>
            ) : null}

            {c3maProfile.map(
              (profile) =>
                false && (
                  <C3maComponent
                    c3maId={profile.id}
                    fullName={profile.member.name}
                    imageLink={profile.member.picture}
                    songTitle={profile.song_title}
                    facebook={profile.member.facebook_profile}
                    twitter={profile.member.twitter_handle}
                    linkedin={profile.member.linkedin_profile}
                    voteValue={profile.vote_count}
                  />
                )
            )}
          </div>
        </div>
        <br />
        <br />
      </section>

      <br />
      <br />
      <br />

      <Footer />
    </div>
  );
}
