import React, { Component } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import ContentIframe from "../components/ContentIframe";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Subscribe from "../components/Subscribe";
import {readTime} from "../functions/Functions"; 

export default class Blog extends Component {
  state = {
    blog: {},
    loading: true,
    blogs: [],
    loadingPopular: true,
    loadingIframe: true,
  };

  search(id) {
    this.setState({ loading: true });
    axios
      .get(`/v1/api/blog/${id}`)
      .then((res) => {
        this.setState({ blog: res.data, loading: false });
        axios.get(`/v1/api/blog_view/${id}`);
      });
  }

  componentDidMount() {
    const { handle } = this.props.match.params;

    axios
      .get(`/v1/api/blog/${handle}`)
      .then((res) => {
        this.setState({ blog: res.data, loading: false });
        axios.get(`/v1/api/blog_view/${handle}`);
      });

    axios
      .get("/v1/api/blog", {
        params: {
          popular: true,
        },
      })
      .then((res) => {
        this.setState({ blogs: res.data.results, loadingPopular: false });
      });
  }

  parentLoading = (value) => {
    this.setState({ loadingIframe: value });
  };

  render() {
  

    return (
      <div>
        <Header />
        <Banner
          page={this.state.blog.title}
          middlePage="Blog"
          middleLink="/blog"
          pageName="Blog Details"
        />

        <section className="blog_area single-post-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 posts-list">
                <div className="single-post row">
                  <div className="col-lg-12">
                    {this.state.loading ? (
                      <div className="col-lg-12">
                        <center>
                          <Loader
                            type="TailSpin"
                            color="#000000"
                            height={100}
                            width={100}
                          />
                        </center>
                      </div>
                    ) : null}

                    {this.state.blog.image ? (
                      <div className="feature-img">
                        <img
                          className="img-fluid"
                          src={this.state.blog.image}
                          alt=""
                        />
                        <br />
                      </div>
                    ) : null}

                    <div className="col-lg-12 col-md-12 blog_details">
                      <center>
                        <h2>{this.state.blog.title}</h2>
                      </center>
                      <br />
                    </div>

                    {/* <div
                      class="img-fluid"
                      dangerouslySetInnerHTML={{
                        __html: this.state.blog.content,
                      }}
                    ></div> */}

                    <div className="img-fluid">
                      {this.state.loadingIframe ? (
                        <div className="col-lg-12">
                          <center>
                            <Loader
                              type="TailSpin"
                              color="#000000"
                              height={100}
                              width={100}
                            />
                          </center>
                        </div>
                      ) : null}
                      <ContentIframe
                        content={this.state.blog.content}
                        parentLoading={this.parentLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <ul className="list_style cat-list">
                      <li>
                        <a href="# " className="d-flex justify-content-between">
                          <p>
                            <i className="lnr lnr-calendar-full"></i> Date
                            Published
                          </p>
                          <p>{readTime(this.state.blog.created_on)}</p>
                        </a>
                      </li>

                      <li>
                        <a href="#" className="d-flex justify-content-between">
                          <p>
                            <i className="lnr lnr-eye"></i> Views
                          </p>
                          <p>{this.state.blog.views} </p>
                        </a>
                      </li>

                      <li>
                        <a href="#" className="d-flex justify-content-between">
                          <p>
                            <i className="lnr lnr-tag"></i> Category{" "}
                          </p>
                          <p>{this.state.blog.category_name} </p>
                        </a>
                      </li>
                    </ul>
                  </aside>

                  <aside className="single_sidebar_widget popular_post_widget">
                    <br />
                    <h3 className="widget_title">Popular Posts</h3>

                    {this.state.loadingPopular ? (
                      <div classNameName="col-lg-12">
                        <center>
                          <Loader
                            type="TailSpin"
                            color="#000000"
                            height={60}
                            width={60}
                          />
                        </center>
                      </div>
                    ) : null}

                    {this.state.blogs.slice(0, 4).map((blog) => (
                      <div className="media post_item">
                        {/* <img src="/image/blog/post1.jpg" alt="post" /> */}

                        <div className="media-body">
                          <Link
                            to="#"
                            onClick={() => {
                              this.search(blog.slug);
                            }}
                          >
                            <h3>{blog.title}</h3>
                          </Link>

                          <p>
                            <i className="lnr lnr-calendar-full"></i>{" "}
                            {readTime(blog.created_on)}{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {"  "} | {"  "}{" "}
                            &nbsp; <i className="lnr lnr-eye"></i> {blog.views}
                          </p>
                        </div>
                      </div>
                    ))}

                    <div className="br"></div>
                  </aside>

                  <Subscribe />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
