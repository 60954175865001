import React, { Component } from "react";
import "../App.css";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import NewsLetterComponent from "../components/NewsLetterComponent";
import axios from "axios";
import {readTime} from "../functions/Functions";  

export default class StaffDetails extends Component {
  state = {
    profile: {},
    newsletters: [],
  };
  componentDidMount() {
    const { handle } = this.props.match.params;

    axios
      .get(`/v1/api/profile/${handle}`)
      .then((res) =>
        this.setState({ profile: res.data, newsletters: res.data.newsletters })
      );
  }

  render() {
    

    return (
      <div className="App">
        <Header />
        <Banner
          page={this.state.profile.full_name}
          middlePage="Staff"
          middleLink="/staff"
          pageName="Staff Profile"
        />

        <section class="blog_area">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div class="blog_right_sidebar">
                  {/* <aside class="single_sidebar_widget ads_widget">
                    <a href="#">
                      <img class="img-fluid" src="image/blog/add.jpg" alt="" />
                    </a>
                    <div class="br"></div>
                  </aside> */}

                  <aside class="single_sidebar_widget author_widget">
                    <img
                      class="author_img rounded-circle"
                      src={this.state.profile.image}
                      alt=""
                    />
                    <br /> <br />
                    <h4> {this.state.profile.full_name} </h4>
                    <p>{this.state.profile.position}</p>
                    <div class="social_icon">
                      {this.state.profile.facebook_link === "" ? (
                        ""
                      ) : (
                        <a
                          href={this.state.profile.facebook_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      )}

                      {this.state.profile.twitter_link === "" ? (
                        ""
                      ) : (
                        <a
                          href={this.state.profile.twitter_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      )}

                      {this.state.profile.instagram_link === "" ? (
                        ""
                      ) : (
                        <a
                          href={this.state.profile.instagram_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      )}
                    </div>
                    <p style={{ textAlign: "justify" }}>
                      {this.state.profile.about}
                    </p>
                    <div class="br"></div>
                  </aside>

                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list_style cat-list">
                      <li>
                        <a href="# " class="d-flex justify-content-between">
                          <p>News Letters </p>
                          <p> {this.state.profile.no_newsletters} </p>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#" class="d-flex justify-content-between">
                          <p>Years of experience</p>
                          <p>24</p>
                        </a>
                      </li> */}
                    </ul>
                  </aside>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="blog_left_sidebar">
                  <center>
                    {" "}
                    <h3 class="widget_title">News Letters </h3>
                  </center>

                  {this.state.profile.no_newsletters === "0" ? (
                    <div>
                      <hr />
                      <br />
                      <br />
                      <center>
                        {" "}
                        <h3> No Newsletters Available </h3>
                      </center>
                    </div>
                  ) : (
                    <div />
                  )}

                  {this.state.newsletters.map((newletter) => (
                    <NewsLetterComponent
                      title={newletter.title}
                      date={readTime(newletter.date_pulished)}
                      description={newletter.short_description}
                      file={newletter.file}
                    />
                  ))}

                  {/* <nav class="blog-pagination justify-content-center d-flex">
                    <ul class="pagination">
                      <li class="page-item">
                        <a href="#" class="page-link" aria-label="Previous">
                          <span aria-hidden="true">
                            <span class="lnr lnr-chevron-left"></span>
                          </span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">
                          01
                        </a>
                      </li>
                      <li class="page-item active">
                        <a href="#" class="page-link">
                          02
                        </a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">
                          03
                        </a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">
                          04
                        </a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link">
                          09
                        </a>
                      </li>
                      <li class="page-item">
                        <a href="#" class="page-link" aria-label="Next">
                          <span aria-hidden="true">
                            <span class="lnr lnr-chevron-right"></span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
