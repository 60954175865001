import React, { Component } from "react";
import "../App.css";
import axios from "axios";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import DonationFormComponent from "../components/DonationFormComponent";
import PlayListComponent from "../components/PlayListComponent";
import Loader from "react-loader-spinner";
import { withAlert } from "react-alert";

// import "react-notification-alert/dist/animate.css";
// import { withAlert } from 'react-alert'
// import NotificationAlert from 'react-notification-alert';

// var options = {};
// options = {
//     place: 'tc',
//     message: (
//         <div>
//             <div>
//                Donation Reminder Successfully added
//             </div>
//         </div>
//     ),
//     type: "success",
//     autoDismiss: 7,
//     closeButton: false

// }

class AlbumDetails extends Component {
  state = {
    album: {},
    loading: true,
    download: false,
    message: "",
  };
  componentDidMount() {
    const { handle } = this.props.match.params;

    axios
      .get(`https://www.fsmission.org/v1/api/album/${handle}`)
      .then((res) => this.setState({ album: res.data, loading: false }));
  }

  alert = this.props.alert;

  download = (msg) => {
    this.setState({ download: true, message: msg });
    this.alert.show(
      <div>
        <div>{msg}</div>
      </div>,
      {
        timeout: 5000,
        type: "success",
      }
    );

    // this.refs.notify.notificationAlert(options);
  };

  render() {
    return (
      <div className="App">
        <Header />
        <Banner
          page={this.state.album.title}
          pageName="songs"
          middlePage="Album"
          middleLink="/album"
        />

        <section class="blog_area">
          <div class="container">
            <div class="row">
              {this.state.loading ? (
                <div className="col-lg-12">
                  <center>
                    <Loader
                      type="TailSpin"
                      color="#000000"
                      height={100}
                      width={100}
                    />
                  </center>
                </div>
              ) : (
                ""
              )}

              <div class="col-lg-4">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget ads_widget">
                    {/* <h4 class="widget_title">Newsletter</h4> */}

                    <img
                      className="img-fluid"
                      src={this.state.album.image}
                      alt=""
                    />

                    {/* <br/><br/>
                                <a href="#" class="genric-btn primary radius ">Download Album</a> */}
                    <div class="br"></div>
                  </aside>

                  <aside class="single_sidebar_widget author_widget">
                    <h4>{this.state.album.title} </h4>
                    <p>No of songs: {this.state.album.songs_no} </p>
                    <br />
                    <p>{this.state.album.dedication}</p>
                    <div class="br"></div>
                  </aside>

                  <aside class="single-sidebar-widget newsletter_widget">
                    <p>{this.state.album.acknoledgement}</p>
                    {/* <div class="form-group d-flex flex-row">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Enter email"
                          onfocus="this.placeholder = ''"
                          onblur="this.placeholder = 'Enter email'"
                        />
                      </div>
                      <a href="#" class="bbtns">
                        Subcribe
                      </a>
                    </div> */}
                    <p class="text-bottom">{this.state.album.copyright}</p>
                  </aside>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="blog_right_sidebar">
                  {/* <BlogComponent /> */}

                  <aside class="single_sidebar_widget popular_post_widget">
                    {this.state.album.enable_donation &&
                    !this.state.download ? (
                      <DonationFormComponent
                        donationMessage={this.state.album.donation_message}
                        parentFunction={this.download}
                        albumID={this.state.album.id}
                      />
                    ) : (
                      ""
                    )}

                    {!this.state.album.enable_donation &&
                    !this.state.loading &&
                    !this.state.download ? (
                      <PlayListComponent media={this.state.album.media} />
                    ) : (
                      ""
                    )}

                    {this.state.download && !this.state.loading ? (
                      <PlayListComponent media={this.state.album.media} />
                    ) : (
                      ""
                    )}
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {/* <NotificationAlert ref="notify" zIndex={9999} onClick={() => console.log("hey")} /> */}
      </div>
    );
  }
}

export default withAlert()(AlbumDetails);
