import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";

export default function Subscribe() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [returnMessage, setReturnMessage] = useState({});
  const [data, setResponse] = useState({});
  const [submit, setSubmit] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    setFields({ ...fields, [name]: event.target.value });
  };



  const handleSubmit = (event) => {
      setSubmitLoading(true);
      event.preventDefault();
      setReturnMessage({});

      axios
        .post("/v1/api/subscriber", fields, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            setResponse(response.data);
            setSubmit(true);
          }
          setSubmitLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            const response_error = error.response.data;
            setReturnMessage(response_error);
            console.log(response_error);
            setSubmitLoading(false);
          }
        });
    
  };

 
    return (
      <aside class="single-sidebar-widget newsletter_widget">
        <h4 class="widget_title">Newsletter</h4>
        <p>
          For update on FSM events, Outreach and News letters, kindly subscribe
        </p>
        <div class="form-group d-flex flex-row">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </div>
            </div>
            <input
              name="email"
              type="text"
              class="form-control"
              id="inlineFormInputGroup"
              placeholder="Enter email"
              onfocus="this.placeholder = ''"
              onblur="this.placeholder = 'Enter email'"
              onChange={handleChange}
            />
           

          </div>

          {submitLoading ? (
                            <Loader
                            className="bbtns" 
                              type="TailSpin"
                              color="#FFFFFF"
                              height={20}
                              width={20}
                            />
                          ) : (
                           


<a  href="# " className="bbtns" disabled={submitLoading} onClick={handleSubmit}>
            Subcribe
          </a>

                          )}



          
        </div>
        {returnMessage.email ? (
                            <small className="text-danger">
                              Subscriber with this email already exists
                            </small>
                          ) : null}


<div className="mt-10 success"> 

{submit && (<p style={{color: "white"}}> 
  Successfully subscribed  <strong>{data.name}</strong> 
  </p>)}

</div>
        <p class="text-bottom">You can unsubscribe at any time</p>
        <div class="br"></div>
      </aside>
    );
}
