import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Loader from "react-loader-spinner";
import VideoStreamComponent from "../components/VideoStreamComponent";
import VideoStreamComponentLive from "../components/VideoStreamComponentLive";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

export default function LiveStream() {

  const [loading, setLoading] = useState(true);
  const [fsmVideos, setFsmVideos] = useState([]);
  const [nextPage , setNext] = useState("")
  const [prevPage , setPrev] = useState("")

useEffect(() => {
    axios.get("/v1/api/videos_streams").then((res) => {
        setFsmVideos(res.data.data)
        setLoading(false);
        if (res.data.paging.hasOwnProperty("next")){
            setNext(res.data.paging.next)
        }

        if (res.data.paging.hasOwnProperty("previous")){
            setPrev(res.data.paging.previous)
        } 

    });
  }, []);



  const nextPageBtn = () => {

    setLoading(true)
    setFsmVideos([])
    axios.get(`/v1/api/videos_streams?after=${nextPage}`).then((res) => {
        setFsmVideos(res.data.data)
        if (res.data.paging.hasOwnProperty("next")){
            setNext(res.data.paging.next)
        } else {
            setNext("")
        }

        if (res.data.paging.hasOwnProperty("previous")){
            setPrev(res.data.paging.previous)
        } else {
            setPrev("")
        }
        setLoading(false);
    });
}


    const prevPageBtn = () => {
        setLoading(true)
        setFsmVideos([])
        axios.get(`/v1/api/videos_streams?before=${prevPage}`).then((res) => {
            setFsmVideos(res.data.data)
            if (res.data.paging.hasOwnProperty("next")){
                setNext(res.data.paging.next)
            } else {
                setNext("")
            }
    
            if (res.data.paging.hasOwnProperty("previous")){
                setPrev(res.data.paging.previous)
            } else {
                setPrev("")
            }
            setLoading(false);
        });
    }
    








const truncate_word = (reference) => {
  return reference.length > 70  ? reference.substring(0, 70) + "..." : reference;
}


  return (
    <div className="App">
      <Header />
      <Banner page="Live Stream" pageName="Videos" />




      <section className="blog_area">
          <div className="container">
            <div className="row">



            { loading ? (<div className="col-lg-12"><center><Loader type="TailSpin" color="#000000" height={100} width={100} /></center></div>): ""  } 
             {fsmVideos.map((video) => (

            video.status == "LIVE" ? 
                <div>
                   <h4> On Going </h4> 
                <VideoStreamComponentLive 
                video={video.id}
                permalinkUrl={video.permalink_url}
                title={video.hasOwnProperty("title") ? truncate_word(video.title) : "" }
                description={video.hasOwnProperty("description") ? truncate_word(video.description) : "" }
                creation_time={video.creation_time}
                /></div>
             :
              <VideoStreamComponent 
              video={video.id}
              permalinkUrl={video.permalink_url}
              title={video.hasOwnProperty("title") ? truncate_word(video.title) : "" }
              description={video.hasOwnProperty("description") ? truncate_word(video.description) : "" }
              creation_time={video.creation_time}
              />
           
            
            
            ))}

            </div>



            <nav className="blog-pagination justify-content-center d-flex">
                <ul className="pagination">

                    {prevPage != "" ? (<li className="page-item">
                    <a href="#" className="page-link" aria-label="Previous" onClick={prevPageBtn}>
                        <span aria-hidden="true">
                        <span className="lnr lnr-chevron-left"></span>
                        </span>
                        Previous
                    </a>
                    </li>): ""}
                    

                    {nextPage != "" ? (<li className="page-item">
                    <a href="#" className="page-link" aria-label="Next" onClick={nextPageBtn}>
                        Next
                        <span aria-hidden="true">
                        <span className="lnr lnr-chevron-right"></span>
                        
                        </span>
                    </a>
                    </li>): ""}
                    
                </ul>
            </nav>

         

          </div>
        </section>



    

      <Footer />
    </div>
  );
}
