import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

export default function LookRegistration() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [data, setResponse] = useState({});
  const [returnMessage, setReturnMessage] = useState({});
  const [submit, setSubmit] = useState(false);
  const [category, setCategory] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const closed = false;

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "category") setCategory(event.target.value);

    setFields({ ...fields, [name]: event.target.value });
  };

  const handleSubmit = (event) => {
    setSubmitLoading(true);
    setReturnMessage({});
    if (phoneNumber && isPossiblePhoneNumber(phoneNumber)) {
      event.preventDefault();

      setReturnMessage({});

      axios
        .post("/v1/api/look", fields, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            setResponse(response.data);
            setSubmit(true);
          }
          setSubmitLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            const response_error = error.response.data;
            setReturnMessage(response_error);
            console.log(response_error);
            setSubmitLoading(false);
          }
        });
    } else {
      event.preventDefault();
      setIsPhoneValid(false);
      setSubmitLoading(false);
    }
  };

  const selectCountry = (val) => {
    setCountry(val);
    setFields({ ...fields, country_of_residence: val });
  };

  const selectRegion = (val) => {
    setRegion(val);
    setFields({ ...fields, state_region: val });
  };

  const selectPhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setFields({ ...fields, phone_number: phone });
  };

  return (
    <div>
      <Header />
      <Banner page="LOOK 2024" pageName="Registration" />

      <section className="contact_area section_gap whole-wrap">
        <div className="container">
          <div className="page-cover text-center">
            <div className="row justify-content-center">
              <div className="col-md-6  align-self-center">
                <div className="donation_form registration">
                  {submit && (
                    <div>
                      <h3>LOOK Form Submitted Successfully</h3>
                      <p>
                        Hi <strong>{data.name}</strong> , Your registration is
                        successful. We willl send you a confirmation email to{" "}
                        {data.email} on how to participate in the conference .
                        <br />
                        <br />

                        To get timely update, kindly join the LOOK 2024 WhatsApp group via the link below.

                        https://chat.whatsapp.com/BlWOU3akYQC7IWVhPSamPU <br/>


                        You can check out what we have been up to on our Blog
                      </p>

                      <Link to="/blog" className="btn_hover btn_hover_two">
                        Visit Blog
                      </Link>
                      <br />

                      <Link to="/" className="genric-btn link">
                        Back to Home Page
                      </Link>
                    </div>
                  )}

                  {!submit && closed && (
                    <div>
                      <h3>LOOK Registration closed</h3>
                      <p>
                        Registration for LOOK2024 is now closed
                        <br />
                        <br />
                        You can check out what we have been up to on our Blog
                      </p>

                      <Link to="/blog" className="btn_hover btn_hover_two">
                        Visit Blog
                      </Link>
                      <br />

                      <Link to="/" className="genric-btn link">
                        Back to Home Page
                      </Link>
                    </div>
                  )}

                  {!closed && !submit && (
                    <form onSubmit={handleSubmit} method="post">
                      <h3>LOOK 2024 Registration</h3>
                      <p>
                        <Link to="/look"> LOOK </Link>is an annual mission
                        conference of the Foundation for Student Mission. (FSM),
                        with a commission to mobilize and equip Christian
                        students as workforce for several Churches and mission activities. <br />
                        <br />
                        <strong>THEME: The SOWER, THE SEED AND THE GROUND - MATT 13:18-23 </strong>
                        Are you ready to rise above challenges and become a dynamic force in God's mission workforce? 
                        Unleash your potential and make a difference at LOOK 2024! 
                        
                        <br/><br/>
                        This event will ignite your passion, align and prepare your heart to
                         be the fertile ground God can use to transform your campus, workplace, and the world.


                      </p>
                      <hr />
                      <div className="row">
                        <div className="form-group col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            onChange={handleChange}
                            required
                          />
                          {returnMessage.email ? (
                            <small className="text-danger">
                              Application with this email already exists
                            </small>
                          ) : null}
                        </div>

                        <div className="form-group col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-group col-md-12">
                          <CountryDropdown
                            defaultOptionLabel="Country Of Residence"
                            className="form-control"
                            name="country_of_residence"
                            value={country}
                            required
                            onChange={(val) => selectCountry(val)}
                          />
                        </div>

                        <div className="form-group col-md-12">
                          <RegionDropdown
                            blankOptionLabel="No country selected"
                            defaultOptionLabel="State/Region Of Residence"
                            disableWhenEmpty={true}
                            className="form-control"
                            country={country}
                            value={region}
                            onChange={(val) => selectRegion(val)}
                            required
                            name="state_region"
                          />
                        </div>

                        <div className="form-group col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City Of Residense"
                            name="city_of_residence"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group col-md-12">
                          {/* <br />
                      <p> Preferably WhatsApp Number</p> */}
                          <PhoneInput
                            international
                            required
                            defaultCountry="NG"
                            className="form-control"
                            countryCallingCodeEditable={false}
                            placeholder="Phone Number (Preferably Whatsapp)"
                            value={phoneNumber}
                            onChange={selectPhoneNumber}
                          />
                          {isPhoneValid ? null : (
                            <small className="text-danger">
                              Invalid Phone Number
                            </small>
                          )}

                          {returnMessage.phone_number ? (
                            <small className="text-danger">
                              Application with this Phone Number already exists
                            </small>
                          ) : null}
                        </div>

                        <div className="form-group col-md-12 ">
                          <select
                            className="form-control"
                            name="age_group"
                            onChange={handleChange}
                            required
                          >
                            <option value=""> Age Group </option>
                            <option value="Below 20">Below 20</option>
                            <option value="21-30"> 21-30 </option>
                            <option value="31-40"> 31-40 </option>
                            <option value="41-50"> 41-50 </option>
                            <option value="51-60"> 51-60 </option>
                            <option value="Above 60"> Above 60 </option>
                          </select>
                        </div>

                        <div className="form-group col-md-12 ">
                          <select
                            className="form-control"
                            name="gender"
                            onChange={handleChange}
                            required
                          >
                            <option value=""> Gender </option>
                            <option value="Male">Male</option>
                            <option value="Female"> Female </option>
                          </select>
                        </div>

                        <div className="form-group col-md-12 ">
                          <select
                            className="form-control"
                            name="attendance_mode"
                            onChange={handleChange}
                            required
                          >
                            <option value=""> Attendance Mode </option>
                            <option value="Physical">Physical</option>
                            <option value="Virtual"> Virtual</option>
                            <option value="Satellite Center"> Satellite Center</option>
                          </select>
                        </div>

                        <div className="form-group col-md-12 ">
                          <select
                            className="form-control"
                            name="member"
                            onChange={handleChange}
                            required
                          >
                            <option value="">Are you A Member of FSM ? </option>
                            <option value="Yes">Yes</option>
                            <option value="No"> No </option>
                          </select>
                        </div>

                        <div className="form-group col-md-12 ">
                          <select
                            className="form-control"
                            name="category"
                            onChange={handleChange}
                            required
                          >
                            <option value=""> Student/Professional </option>
                            <option value="Professional">
                              Professional (Non-Student)
                            </option>
                            <option value="Student">
                              Student (Undergraduate)
                            </option>
                          </select>
                        </div>

                        {category === "Professional" && (
                          <div className="col-md-12 ">
                            <div className="row">
                              <div className="col-md-12">
                                <hr />

                                <p>Professional (Non-Student)</p>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Occupation"
                                  name="occupation"
                                  onChange={handleChange}
                                  required
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Work Place"
                                  name="work_place"
                                  required
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Church Affiliation"
                                  required
                                  name="church_affiliation"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {category === "Student" && (
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12">
                                <hr />

                                <p>Student (Undergraduate)</p>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Institution"
                                  name="institution"
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Course Of Study"
                                  name="course_of_study"
                                  required
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="year_level_part"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Year/Level/Part</option>
                                  <option value="100 level"> 100 level</option>
                                  <option value="200 level"> 200 level</option>
                                  <option value="300 level"> 300 level</option>
                                  <option value="400 level"> 400 level</option>
                                  <option value="500 level"> 500 level</option>
                                  <option value="600 level"> 600 level</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <select
                                  className="form-control"
                                  name="final_year"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">
                                    Are you a final year student?
                                  </option>
                                  <option value="Yes"> Yes</option>
                                  <option value="No"> No</option>
                                </select>
                              </div>

                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Fellowship Affiliation"
                                  required
                                  name="fellowship_affiliation"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="single-element-widget">
                          <br/>
                        <p>
                             <strong>PRE-CONFERENCE: 8 - 13 AUGUST 2024 (virtual)</strong><br/>
                            
                          </p>

                          <br />



                       
                          <p>
                             <strong>MAIN CONFERENCE: 14 – 17 August, 2024 </strong><br/>
                             with physical  venue at the Provinces Coordinating Hall, Redemption City, Lagos – Ibadan Express Way and other satellite venues.

                          </p>


                          <p>
                          <br/>
                            <strong> Main Conference Accommodation and Feeding</strong> <br/>
                            Dormitory accommodation and feeding shall be free as God provides. However, the following optional mode of accommodation shall be at individuals’ expense on booking ahead basis:

<br/><br/>
                                  {/* <ol>
                    <li>
                      <div>
                        <strong> Bethlehem Inn </strong><br/>
                          Chalet - N 7,000 per night <br/><br/>
                        </div>
                    </li>
                                    <li>

                                      <div>
  <strong> Province Coordinating Lodge (limited space).</strong><br/>
- Single Room – N 6,000 per night<br/>
- 2 Bedrooms – N 25,000<br/>
- 3 Bedrooms- N35,000 <br/>

</div>



                                    </li>
                  </ol> */}





Further information, please contact (Tel/WhatsApp) 08066372188, 08137887362


                          </p>

                        </div>

                        <div className="col-md-12 text-center">
                          {submitLoading ? (
                            <Loader
                              type="TailSpin"
                              color="#FF0000"
                              height={50}
                              width={50}
                            />
                          ) : (
                            <button
                              type="submit"
                              className="btn_hover success"
                              disabled={submitLoading}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
