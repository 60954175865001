import React, { Component, PropTypes } from "react";
import ReactDOM from "react-dom";

export default class ContentIframe extends Component {
  constructor() {
    super();
    this.state = {
      iFrameHeight: "0px",
      loading: true,
    };
  }

  render() {
    return (
      <iframe
        style={{
          width: "100%",
          height: this.state.iFrameHeight,
          overflow: "visible",
        }}
        onLoad={() => {
          const obj = ReactDOM.findDOMNode(this);
          this.setState({
            iFrameHeight:
              obj.contentWindow.document.body.scrollHeight + 20 + "px",
          });
          this.props.parentLoading(false);
        }}
        ref="iframe"
        srcDoc={this.props.content}
        width="100%"
        height={this.state.iFrameHeight}
        scrolling="no"
        frameBorder="0"
      />
    );
  }
}
