import React, { useState, useCallback , useEffect } from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../App.css";
import {readTImeEvent} from "../functions/Functions";  
import axios from "axios";
import Loader from "react-loader-spinner";
import GalleryI from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images";


export default function EventDetails(props){
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);


  useEffect(() => {
    const { handle } = props.match.params;
    axios
      .get(`/v1/api/event/${handle}`)
      .then((res) => {
        setEvent(res.data)

        var result = []
        res.data.gallery.map((image) =>  {
          var photo = {}
          photo['src'] = image.image 
          photo['title'] = `${image.caption}  from  ${image.event_name}`
          photo['width'] = image.image_width
          photo['height'] = image.image_height
         
          result.push(photo)
        });
        setPhotos(result);
        setLoading(false);

      }
      );
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  


    return (
      <div className="App">
        <Header />
        <Banner
          page={event.title}
          pageName="Details"
          middlePage="Events"
          middleLink="/events"
        />
        <section class="event_details_area section_gap">
          <div class="container">
            
            { loading ? (<div className="col-lg-12"><center><Loader type="TailSpin" color="#000000" height={100} width={100} /></center></div>): ( <div  class="row">
              <div class="col-md-8">
                <img
                  class="img-fluid"
                  src={
                    event.image ===
                    "https://fsmissionadmin.s3-us-west-2.amazonaws.com/uploads/default.jpg"
                      ? "image/default.jpg"
                      : event.image
                  }
                  alt=""
                />
              </div>
              <div class="col-md-4 align-self-center">
                <ul class="list_style sermons_category event_category">
                  <li>
                    <i class="lnr lnr-user"></i>
                    {readTImeEvent(event.start_date,
                      true
                    )}{" "}
                    {readTImeEvent(event.end_date, false)}
                  </li>
                  <li>
                    <i class="lnr lnr-apartment"></i>{" "}
                    {event.event_location} |{" "}
                    {event.event_venue}
                  </li>
                  {event.registration_link === "" ? (
                    ""
                  ) : (
                    <li>
                      <i class="lnr lnr-location"></i>{" "}
                      <a
                        href={event.registration_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Register Here{" "}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div class="col-md-12 event_details">
                <h2 class="event_title">
                  {event.title}{" "}
                </h2>

                <p>{event.details}</p>
               
              </div>

              </div>) } 
             
            

            

             
            <div className="container" id="gallery">

            { photos.length > 0 ? (<div><br/><hr/><center><h2 class="event_title">Event Gallery </h2></center><br/></div>): ""  } 
              


         
            <GalleryI photos={photos} onClick={openLightbox}/>
            <ModalGateway>
                {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel
                    currentIndex={currentImage}
                    views={photos.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                    }))}
                    />
                </Modal>
                ) : null}
            </ModalGateway>


               
            
            
            </div>
       






          
          </div>
        </section>
        <Footer />
      </div>
    );
  }

